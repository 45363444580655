<template>
  <v-card>
    <v-card-title>
      <span class="headline">
        <v-icon>mdi-pencil-outline</v-icon>{{ allowedAction(constants.cafereoActions) ? "メーカー返品" : "返品" }}編集
      </span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="purchaseReturnForm" v-model="validEntryForm" lazy-validation>
        <v-card-subtitle>返品確認情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                label="返品確認番号"
                v-model="updateModel.returnCommitNumber"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions)">発注情報</v-card-subtitle>
        <v-card-subtitle v-if="allowedAction(constants.supplierActions)">受注情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="4" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field label="発注ID" v-model="updateModel.purchaseId" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" v-if="allowedAction(constants.supplierActions)">
              <v-text-field label="受注ID" v-model="updateModel.purchaseId" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                label="日付"
                v-model="updateModel.purchaseOrderDate"
                type="date"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="12" sm="6"
              ><v-text-field label="発注先略称" v-model="updateModel.maker" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>商品情報</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="4">
              <v-select
                :disabled="
                  !allowedAction(constants.cafereoActions) ||
                  propotionFlg ||
                  !updateModel.productConditionType ||
                  updateModel.returnCommitStatus == 4
                "
                :items="propotionFlg ? productConditionTypeListAll : productConditionTypeList"
                label="商品状態区分"
                filled
                v-model="updateModel.productConditionType"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="JANCode" v-model="updateModel.janCode" filled dense disabled> </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="タイトル" v-model="updateModel.title" filled dense disabled> </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="商品名" v-model="updateModel.productName" filled dense disabled> </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="上代" v-model="updateModel.retailPrice" filled dense disabled> </v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field label="仕入掛率" v-model="updateModel.purchaseRate" filled dense disabled> </v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="仕切単価" v-model="updateModel.unitPrice" filled dense disabled> </v-text-field>
            </v-col>
            <v-col cols="12" sm="3" v-if="allowedAction(constants.cafereoActions)">
              <v-text-field label="発注数" v-model="updateModel.purchaseQuantity" filled dense disabled> </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field
                label="返品数"
                v-model="updateModel.returnQuantity"
                filled
                dense
                @change="updateModel.returnQuantity = $event !== '' ? $event : null"
                :disabled="!updateModel.productConditionType || updateModel.returnCommitStatus == 4"
                :rules="[
                  rules.required,
                  rules.isNumber,
                  rules.maxLength(8),
                  rules.isMinNumber(1),
                  rules.validReturnQuantity,
                ]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="返品上代合計" v-model="updateModel.retailReturnPriceTotal" filled dense disabled>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field label="返品単価合計" v-model="updateModel.unitReturnPriceTotal" filled dense disabled>
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <storage-field
                ref="storage"
                :value="updateModel.warehouseCd"
                v-model="updateModel.warehouseCd"
                :rules="[rules.required]"
                :disabled="!updateModel.productConditionType || updateModel.returnCommitStatus == 4"
              ></storage-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-subtitle>メーカー設定</v-card-subtitle>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-switch
                v-if="allowedAction(constants.cafereoActions)"
                label="メーカー検品結果"
                v-model="updateModel.makerInspectionResult"
                finset
                dense
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                :label="allowedAction(constants.cafereoActions) ? 'メーカー検品数' : '検品数'"
                v-model="updateModel.makerInspectionQuantity"
                filled
                dense
                @change="updateModel.inCtPcsQuantity = stringToNumber($event)"
                :rules="[rules.isNumber, rules.maxLength(8), rules.isMinNumber(0)]"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="allowedAction(constants.cafereoActions)"></v-divider>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions)">付加情報</v-card-subtitle>
        <v-container style="max-width: inherit" v-if="allowedAction(constants.cafereoActions)">
          <v-row dense>
            <v-col cols="12" sm="5">
              <v-text-field
                label="返品受注ID"
                v-model="updateModel.orderReturnNumber"
                filled
                dense
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-divider v-if="allowedAction(constants.cafereoActions)"></v-divider>
        <v-card-subtitle v-if="allowedAction(constants.cafereoActions)">履歴</v-card-subtitle>
        <v-container style="max-width: inherit" v-if="allowedAction(constants.cafereoActions)">
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-text-field label="更新日時" v-model="updateModel.updateDatetime" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="更新者" v-model="updateModel.updateUser" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録日時" v-model="updateModel.createDatetime" filled dense disabled></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
              <v-text-field label="登録者" v-model="updateModel.createUser" filled dense disabled></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import productConditionTypes from "../../consts/productConditionTypes";
import Validation from "../../libs/validation";
import StorageField from "../../components/common/fields/StorageField.vue";
import ConvertUtils from "../../utils/ConvertUtils";
import { statuses as ApiStatus } from "../../libs/api-client";

export default {
  name: "PurchaseReturnEntry",
  props: ["inputModel"],
  components: {
    StorageField,
  },
  data() {
    return {
      stringToNumber: ConvertUtils.stringToNumber,
      // 権限グループ
      constants: {
        cafereoActions: ["C030502"],
        supplierActions: ["M020502"],
      },
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        isNumber: Validation.isNumber,
        isDecimal: Validation.isDecimal,
        isJancode: Validation.isJancode,
        isMinNumber: Validation.isMinNumber,
        validReturnQuantity: (value) => this.validReturnQuantity(value),
      },
      validEntryForm: null,
      propotionFlg: false,
      updateModel: {},
      productConditionTypeList: productConditionTypes.all(false),
      productConditionTypeListAll: productConditionTypes.all(),
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  watch: {
    "updateModel.returnQuantity"(value) {
      this.calcTotal(value);
    },
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
  },
  methods: {
    initUpdateModel(inputModel) {
      this.updateModel = JSON.parse(JSON.stringify(inputModel));
      this.updateModel.purchaseRate = this.updateModel.purchaseRate.toFixed(1);
      this.propotionFlg =
        this.updateModel.productConditionType == productConditionTypes.CONSIGNMENT ||
        this.updateModel.productConditionType == productConditionTypes.BUYINGSALES;
      this.calcTotal(this.updateModel.returnQuantity);
    },
    calcTotal(returnQuantity) {
      this.updateModel.retailReturnPriceTotal = returnQuantity * this.updateModel.retailPrice;
      this.updateModel.unitReturnPriceTotal = returnQuantity * this.updateModel.unitPrice;
    },
    validReturnQuantity() {
      if (Number(this.updateModel.purchaseQuantity) < Number(this.updateModel.returnQuantity))
        return "返品数が発注数を超えています。";
      return true;
    },
    // 更新ボタン押下処理
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          let requestContents = {};
          requestContents = {
            deficiency: {
              returnCommitNumber: this.updateModel.returnCommitNumber,
              productConditionType: Number(this.updateModel.productConditionType),
              returnQuantity: Number(this.updateModel.returnQuantity),
              warehouseCd: this.updateModel.warehouseCd,
              makerInspectionResult: !this.updateModel.makerInspectionResult
                ? false
                : this.updateModel.makerInspectionResult,
              deliveryTime: this.updateModel.deliveryTime,
              deliveryReservation: this.updateModel.deliveryReservation,
              makerInspectionQuantity: Number(this.updateModel.makerInspectionQuantity),
              updateDatetime: this.updateModel.updateDatetime,
            },
          };

          const result = await this.$store.dispatch("purchaseReturn/update", requestContents);
          let resultData = {};
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              resultData = result.data.contents.purchaseReturn;
              this.$dialog.notify.info(`メーカー返品を更新しました(${resultData.returnCommitNumber})`, {
                timeout: 2300,
              });
              this.$emit("onEditSubmit", resultData);
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              var message = "";
              Object.keys(result.data?.header.messages).forEach(function (key) {
                message += result.data?.header.messages[key].join("<br>");
              });
              if (message != "") {
                this.$dialog.warning({
                  title: `メーカー返品編集`,
                  text: message,
                  actions: ["OK"],
                });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseReturnEntry::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      const isValid = this.$refs.purchaseReturnForm.validate();
      if (!isValid) {
        this.$dialog.error({ text: "入力エラーがあります", actions: ["OK"] });
      }
      return isValid;
    },
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
};
</script>
