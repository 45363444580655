<template>
  <div class="ma-0 pa-0">
    <v-divider v-if="separator"></v-divider>
    <v-card-subtitle v-text="title"></v-card-subtitle>
    <v-container style="max-width: inherit"><slot></slot></v-container>
  </div>
</template>

<script>
export default {
  name: "SectionBlock",
  props: {
    title: { type: String, default: "" },
    separator: { type: Boolean, default: false },
  },
};
</script>
