<template>
  <v-card>
    <dialog-bar @expand="$emit('expand', $event)"></dialog-bar>
    <v-card-text class="ma-0 pa-0">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step :complete="step > 1" step="1">発注編集/確認</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="step > 2" step="2">完了</v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <div style="height: 450px; overflow-y: scroll">
              <ag-grid-vue
                style="height: 100%"
                :gridOptions="gridOptions"
                :rowData="inputDataRows"
                class="ag-theme-alpine"
                :columnTypes="columnTypes"
                :frameworkComponents="frameworkComponents"
                @row-data-changed="onRowDataChanged"
              ></ag-grid-vue>
            </div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="secondary" @click="onComplete(true)">キャンセル</v-btn>
            <v-btn color="primary" class="float-right" @click="onSubmit()">発注確定</v-btn>
          </v-stepper-content>
          <v-stepper-content step="2">
            <div style="height: 300px">{{ registCount }}件の発注が完了しました。</div>
            <v-divider class="my-3"></v-divider>
            <v-btn color="primary" class="float-right" @click="onComplete(false)">閉じる</v-btn></v-stepper-content
          >
        </v-stepper-items>
      </v-stepper>
    </v-card-text>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="bulkErrorGrid"
      width="80%"
      height="80%"
      title="発注登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import DialogBar from "../common/DialogBar.vue";
import {
  NumericColumn,
  PercentColumn,
  CheckmarkColumn,
  DateColumn,
  FullDateColumn,
} from "../../models/ag-grid/columnTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import PurchaseTypes from "../../consts/PurchaseTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";

export default {
  name: "PurchaseRegist",
  props: ["inputRows", "entryStocks"],
  components: {
    AgGridVue,
    DialogBar,
    ErrorGridDialog,
  },
  data: () => ({
    step: 1,
    rules: {
      required: Validation.required,
      isNumber: Validation.isNumber,
      isDecimal: Validation.isDecimal,
      isRate: Validation.isRate,
      isMinNumber: Validation.isMinNumber,
      maxRateLength: Validation.maxRateLength,
    },
    errorRows: [],
    errorColmuns: [
      { headerName: "行番号", field: "rowNumber" },
      {
        headerName: "エラー内容",
        field: "errorMessage",
        wrapText: true,
        autoHeight: true,
        cellRenderer: function (param) {
          return param.data.errorMessage.join("<br>");
        },
      },
    ],
    columnTypes: {
      dpNumericColumn: NumericColumn,
      dpPercentColumn: PercentColumn,
      dpDateColumn: DateColumn,
      dpFullDateColumn: FullDateColumn,
      dpCheckmarkColumn: CheckmarkColumn,
    },
    frameworkComponents: {
      dpBooleanFilter: BooleanFilter,
      dpSelectionFilter: SelectionFilter,
    },
    gridOptions: {
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: false,
        editable: false,
      },
      columnDefs: [],
      rowSelection: false,
      suppressRowClickSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
      suppressColumnVirtualisation: true,
    },
    registCount: 0,
    orderColumDefs: [
      {
        headerName: "仕入先略称",
        field: "maker",
      },
      {
        headerName: "発注区分",
        field: "purchaseType",
        filter: "dpSelectionFilter",
        filterParams: { options: PurchaseTypes.all() },
        valueGetter: (params) => PurchaseTypes.of(params.data.purchaseType),
      },
      { headerName: "発注締日", field: "orderClosingDate", type: "dpDateColumn" },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title" },
      { headerName: "商品名", field: "productName" },
      {
        headerName: "受注数",
        field: "orderTotalQuantity",
        type: "dpNumericColumn",
      },

      { headerName: "単位", field: "unit" },
      {
        headerName: "上限数",
        field: "maximumQuantity",
        type: "dpNumericColumn",
      },
      {
        headerName: "発注数",
        field: "purchaseQuantity",
        type: "dpNumericColumn",
        editable: true,
        cellStyle: { backgroundColor: "#FFEDB3" },
        onCellValueChanged: (event) => {
          event.node.setDataValue("surplusQuantity", event.data.purchaseQuantity - event.data.orderTotalQuantity);
          event.node.setDataValue("purchasePriceTotal", event.data.purchaseQuantity * event.data.unitPrice);
          event.node.setDataValue("purchasePcsQuantity", event.data.purchaseQuantity * event.data.inBoxNumber);
        },
      },
      { headerName: "未出荷受注数", field: "unshipedOrderTotalQuantity", type: "dpNumericColumn" },
      { headerName: "未入荷発注数", field: "unarrivaledPurchaseTotalQuantity", type: "dpNumericColumn" },
      {
        headerName: "在庫数",
        field: "stockQuantity",
        type: "dpNumericColumn",
      },
      { headerName: "Box入数", field: "inBoxNumber", type: "dpNumericColumn" },
      { headerName: "発注数(psc)", field: "purchasePcsQuantity", type: "dpNumericColumn" },
      {
        headerName: "余剰数",
        field: "surplusQuantity",
        type: "dpNumericColumn",
      },
      { headerName: "上代", field: "retailPrice", type: "dpNumericColumn" },
      {
        headerName: "仕入掛率",
        field: "purchaseRate",
        type: "dpNumericColumn",
        editable: (event) => {
          return event.data.purchaseType == PurchaseTypes.PURCHASE;
        },
        cellStyle: (event) => {
          return event.data.purchaseType == PurchaseTypes.PURCHASE ? { backgroundColor: "#FFEDB3" } : {};
        },
        onCellValueChanged: (event) => {
          let unitPrice = (event.data.retailPrice * event.data.purchaseRate) / 100;
          switch (event.data.fractionProcessAttribute) {
            case 1:
              unitPrice = Math.floor(unitPrice);
              break;
            case 2:
              unitPrice = Math.round(unitPrice);
              break;
            case 3:
              unitPrice = Math.ceil(unitPrice);
              break;
          }
          event.node.setDataValue("unitPrice", unitPrice);
          event.node.setDataValue("purchasePriceTotal", event.data.purchaseQuantity * event.data.unitPrice);
        },
      },
      { headerName: "仕入単価", field: "unitPrice", type: "dpNumericColumn" },
      { headerName: "仕入合計金額", field: "purchasePriceTotal", type: "dpNumericColumn" },
      {
        headerName: "在庫金額",
        field: "stockPrice",
        type: "dpNumericColumn",
      },
      { headerName: "発売日", field: "releaseDate" },
      { headerName: "直送依頼", field: "directDeliveryRequest", type: "dpCheckmarkColumn" },
      { headerName: "配送コード", field: "deliveryCode" },
      { headerName: "配送先郵便番号", field: "deliveryPostalCode" },
      { headerName: "配送先住所", field: "deliveryAddress" },
      { headerName: "届先法人名", field: "deliveryName1" },
      { headerName: " 届先名称", field: "deliveryName2" },
      { headerName: "メーカー備考", field: "purchaseRemarks" },
      { headerName: "Cafereo備考", field: "cafereoRemarks" },
    ],
    stocksColumDefs: [
      { headerName: "発注区分", field: "purchaseType" },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "商品名", field: "productName" },
      {
        headerName: "在庫数",
        field: "stockQuantity",
        type: "dpNumericColumn",
      },
      {
        headerName: "発注数",
        field: "purchaseQuantity",
        type: "dpNumericColumn",
        editable: true,
        cellStyle: { backgroundColor: "#FFEDB3" },
        onCellValueChanged: (event) => {
          console.log(event);
          event.node.setDataValue("retailPriceTotal", event.data.purchaseQuantity * event.data.retailPrice);
          event.node.setDataValue("purchasePriceTotal", event.data.purchaseQuantity * event.data.purchasePrice);
          event.node.setDataValue("purchasePcsQuantity", event.data.purchaseQuantity * event.data.inBoxQuantity);
        },
      },
      { headerName: "単位", field: "unit" },
      {
        headerName: "上限数",
        field: "maximumNumber",
        type: "dpNumericColumn",
      },
      { headerName: "発注締日", field: "orderClosingDate" },
      { headerName: "発売日", field: "releaseDate" },
      { headerName: "Box入数", field: "inBoxQuantity", type: "dpNumericColumn" },
      { headerName: "商品備考（メーカー）", field: "productMakerRemarks" },
      { headerName: "商品備考（Cafereo）", field: "productRemarks" },
      {
        headerName: "上代",
        field: "retailPrice",
        type: "dpNumericColumn",
      },
      {
        headerName: "仕入掛率",
        field: "purchaseRate",
        type: "dpPercentColumn",
        editable: true,
        cellStyle: { backgroundColor: "#FFEDB3" },
        onCellValueChanged: (event) => {
          event.node.setDataValue("purchasePrice", (event.data.retailPrice * event.data.purchaseRate) / 100);
          event.node.setDataValue("purchasePriceTotal", event.data.purchaseQuantity * event.data.purchasePrice);
        },
      },
      {
        headerName: "仕入単価",
        field: "purchasePrice",
        type: "dpNumericColumn",
      },
      {
        headerName: "発注数(psc)",
        field: "purchasePcsQuantity",
        type: "dpNumericColumn",
      },
      {
        headerName: "合計金額（上代）",
        field: "retailPriceTotal",
        type: "dpNumericColumn",
      },
      {
        headerName: "仕入合計金額",
        field: "purchasePriceTotal",
        type: "dpNumericColumn",
      },
      { headerName: "支払サイト", field: "paymentSite" },
      { headerName: "メーカー名", field: "maker" },
      { headerName: "メーカー担当者", field: "makerPerson" },
    ],
    inputDataRows: [],
  }),
  mounted() {
    var allColumnIds = [];
    this.gridOptions.columnApi.getAllColumns().forEach(function (column) {
      allColumnIds.push(column.colId);
    });
    this.gridOptions.columnApi.autoSizeColumns(allColumnIds);
  },
  beforeMount() {
    if (this.entryStocks) {
      this.gridOptions.columnDefs.push(...this.stocksColumDefs);
    } else {
      this.gridOptions.columnDefs.push(...this.orderColumDefs);
    }
    this.inputDataRows = JSON.parse(JSON.stringify(this.inputRows));
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    async onSubmit() {
      try {
        this.loadingOn();
        this.registCount = 0;
        const updateModels = [];
        const date = new Date();
        const Y = date.getFullYear();
        const M = ("00" + (date.getMonth() + 1)).slice(-2);
        const D = ("00" + date.getDate()).slice(-2);
        this.errorRows = [];
        var isValid = true;
        this.gridOptions.api.forEachNode((node) => {
          var error = this.validateRow(node.data);
          if (error != true) {
            this.errorRows.push({
              rowNumber: node.childIndex + 1,
              errorMessage: error,
            });
            isValid = false;
          }
          let purchase = {
            purchaseType: this.entryStocks ? 1 : Number(node.data.purchaseType),
            makerCode: node.data.makerCd,
            productCode: node.data.productCd,
            retailPrice: Number(node.data.retailPrice),
            purchaseRate: parseFloat(node.data.purchaseRate),
            unitPrice: this.entryStocks ? Number(node.data.purchasePrice) : Number(node.data.unitPrice),
            paymentSite: "",
            directDeliveryRequest: this.entryStocks ? false : node.data.directDeliveryRequest,
            purchasePerson: this.$store.getters["security/loggedInUserId"],
            makerPerson: node.data.makerPerson,
            deliveryTime: "",
            deliveryReservation: null,
            deliveryCompany: null,
            purchaseRemarks: "",
            remainingQuantity: 0,
            badProductQuantity: 0,
            deficiencyQuantity: 0,
            excessQuantity: 0,
            cafereoRemarks: "",
            orderId: this.entryStocks ? [] : node.data.orderIdList,
            directDeliveryLocalApproval: this.entryStocks ? false : node.data.directDeliveryLocalApproval,
            directDeliveryRequestDate: Y + "-" + M + "-" + D,
            directDeliveryApproval: false,
            directDeliveryResponsePerson: "",
            directDeliveryResponseDate: null,
            purchaseQuantity: Number(node.data.purchaseQuantity),
            deliveryCode: node.data.deliveryCode,
          };
          updateModels.push(purchase);
        });
        if (isValid) {
          // バッチ化のためListを直接送信するよう変更
          // let requestContents = {
          //   purchases: updateModels,
          // };

          let errorRows = [];
          let result = await this.$store.dispatch("purchase/entryBatch", updateModels);
          let error = result.data?.header;
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.step++;
              this.registCount = updateModels.length;
              this.$dialog.notify.info(`発注が完了しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    rowNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              this.registCount = updateModels.length - errorRows.length;
              this.$refs.bulkErrorGrid.open({ title: "発注登録", records: errorRows });
              this.step++;
              this.$dialog.notify.error(`発注登録に失敗したデータが存在します。ご確認ください。`, {
                timeout: 2300,
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } else {
          this.$refs.bulkErrorGrid.open({ title: "発注登録", records: this.errorRows });
          this.$dialog.notify.error(`入力エラーが存在します。ご確認ください。`, {
            timeout: 2300,
          });
        }
      } catch (error) {
        console.error("PurchaseSearch::onPurchaseEntrySubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onComplete(canceled) {
      this.$emit("complete", canceled);
      this.step = 1;
    },
    onRowDataChanged() {
      this.gridOptions.columnApi.autoSizeAllColumns();
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // 発注数
      this.setValidMessage(this.rules.required(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(this.rules.isNumber(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(this.rules.isMinNumber(1)(row.purchaseQuantity), "発注数", messages);
      this.setValidMessage(this.purchaseQuantityRules(row.purchaseQuantity, row.unit), "発注数", messages);
      // 仕入掛率
      this.setValidMessage(this.rules.required(row.purchaseRate), "仕入掛率", messages);
      this.setValidMessage(this.rules.isDecimal(row.purchaseRate), "仕入掛率", messages);
      this.setValidMessage(this.rules.isRate(row.purchaseRate), "仕入掛率", messages);
      this.setValidMessage(this.rules.maxRateLength(3, 1)(row.purchaseRate), "仕入掛率", messages);

      if (messages.length > 0) ret = messages;

      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    purchaseQuantityRules(value, checkValue) {
      if (value == null) return true;
      if (Number(value) % Number(checkValue) !== 0) return `単位の倍数を入力してください`;
      return true;
    },
  },
};
</script>
