<template>
  <v-dialog v-model="show" :max-width="options.width" persistent scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">
          <v-icon>{{ options.icon }}</v-icon
          >{{ options.title }}
        </span>
      </v-card-title>
      <v-card-subtitle class="my-3 pb-0" v-if="options.subtitle && options.subtitle.length > 0">
        {{ options.subtitle }}
      </v-card-subtitle>
      <slot name="header" :options="options" :records="dataRecords"></slot>
      <v-divider class="my-2"></v-divider>
      <v-card-text :style="{ height: options.height }">
        <!-- <v-row no-gutters class="mb-2">
          <v-col>
            <v-btn class="float-right" small color="accent">
              <v-icon small>mdi-download</v-icon>エラー情報ダウンロード
            </v-btn>
          </v-col>
        </v-row> -->
        <v-row no-gutters style="width: 100%; height: 100%">
          <v-col style="width: 100%; height: 100%">
            <ag-grid-vue
              class="ag-theme-alpine"
              v-model="dataRecords"
              @row-data-changed="onRowDataChanged"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :columnDefs="columnDefs"
              :rowSelection="rowSelection"
              :suppressCellSelection="suppressCellSelection"
              :singleClickEdit="singleClickEdit"
              :pagination="pagination"
              :localeText="localeText"
              :columnTypes="columnTypes"
              :frameworkComponents="frameworkComponents"
              style="width: 100%; height: 100%"
            ></ag-grid-vue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="secondary" @click="onCancel">{{ options.btnCancel }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onSubmit">{{ options.btnSubmit }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<style>
.v-date-picker-table {
  height: auto !important;
}
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { CheckboxCellRenderer } from "../../components/ag-grid/cellRenderers";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
} from "../../models/ag-grid/columnTypes";
import {
  DateCellEditor,
  CheckboxCellEditor,
  AboutDateCellEditor,
  CustomDateCellEditor,
} from "../../components/ag-grid/cellEditors";

export default {
  name: "DatepickerConfirmGridDialog",
  props: {
    icon: { type: String, default: "mdi-checkbox-marked-circle-outline" },
    title: { type: String, default: "対象確認" },
    subtitle: { type: String, default: null },
    btnSubmit: { type: String, default: "確定" },
    btnCancel: { type: String, default: "キャンセル" },
    width: { type: String, default: "100%" },
    height: { type: String, default: "350px" },
    columns: { type: Array, default: () => [] },
    records: { type: Array, default: () => [] },
    messageField: { type: String, default: "_messages" },
    autoClose: { type: Boolean, default: true },
    abountDate: { type: Boolean, default: true },
  },
  components: {
    AgGridVue,
    /* eslint-disable vue/no-unused-components */
    AboutDateCellEditor,
    CustomDateCellEditor,
  },
  data() {
    return {
      show: false,
      options: {
        icon: this.icon,
        title: this.title,
        subtitle: this.subtitle,
        btnSubmit: this.btnSubmit,
        btnCancel: this.btnCancel,
        width: this.width,
        height: this.height,
        messageField: this.messageField,
        autoClose: this.autoClose,
      },
      callback: {
        submit: null,
        cancel: null,
      },
      defaultColDef: null,
      columnDefs: null,
      rowSelection: false,
      suppressCellSelection: true,
      singleClickEdit: true,
      pagination: false,
      localeText: AG_GRID_LOCALE_JA,
      columnTypes: null,
      frameworkComponents: null,
      dataRecords: this.records,
      gridApi: null,
      columnApi: null,
      gridOptions: null,
      heightGrid: "100%",
      widthGrid: "100%",
    };
  },
  beforeMount() {
    this.defaultColDef = {
      resizable: true,
      sortable: false,
      filter: false,
      editable: false,
    };
    this.columnDefs = this.columns;
    this.columnTypes = {
      dpNumericColumn: { ...NumericColumn, filter: false },
      dpPercentColumn: { ...PercentColumn, filter: false },
      dpDateColumn: DateColumn,
      dpFullDateColumn: FullDateColumn,
      dpCheckmarkColumn: CheckmarkColumn,
      dpEditableColumn: EditableColumn,
      dpEditableDateColumn: EditableDateColumn,
    };
    console.log(AboutDateCellEditor);
    this.frameworkComponents = {
      dpDateCellEditor: DateCellEditor,
      dpCheckboxCellRenderer: CheckboxCellRenderer,
      dpCheckboxCellEditor: CheckboxCellEditor,
      dpCellDatePicker: this.abountDate ? AboutDateCellEditor : CustomDateCellEditor,
    };
    this.onRowDataChanged();
  },
  watch: {
    icon(value) {
      this.options.icon = value;
    },
    title(value) {
      this.options.title = value;
    },
    subtitle(value) {
      this.options.subtitle = value;
    },
    btnSubmit(value) {
      this.options.btnSubmit = value;
    },
    btnCancel(value) {
      this.options.btnCancel = value;
    },
    width(value) {
      this.options.width = value;
    },
    height(value) {
      this.options.height = value;
    },
    columns(value) {
      this.gridApi.setColumnDefs(value);
    },
    records(value) {
      this.dataRecords = value;
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.onRowDataChanged();
    },
    open(options = {}) {
      if ("icon" in options) this.options.icon = options.icon;
      if ("title" in options) this.options.title = options.title;
      if ("subtitle" in options) this.options.subtitle = options.subtitle;
      if ("btnSubmit" in options) this.options.btnSubmit = options.btnSubmit;
      if ("btnCancel" in options) this.options.btnCancel = options.btnCancel;
      if ("width" in options) this.options.width = options.width;
      if ("height" in options) this.options.width = options.height;
      if ("columns" in options) {
        if (this.gridApi) {
          this.gridApi.setColumnDefs(options.columns);
        } else {
          this.columnDefs = options.columns;
        }
      }
      if ("records" in options) {
        this.dataRecords = options.records;
      }
      this.callback.validate = options.onValidate;
      this.callback.validateRow = options.onValidateRow;
      this.callback.submit = options.onSubmit;
      this.callback.cancel = options.onCancel;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    onSubmit() {
      this.gridApi.stopEditing(false);
      if (this.options.autoClose) {
        this.close();
      }
      this.$emit("submit", this.dataRecords);
      if (typeof this.callback.submit === "function") {
        this.callback.submit(this.dataRecords);
      }
    },
    onCancel() {
      this.close();
      this.$emit("cancel");
      if (typeof this.callback.cancel === "function") {
        this.callback.cancel();
      }
    },
    onRowDataChanged() {
      if (this.columnApi) {
        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.columnApi.autoSizeColumns(allColumnIds);
      }
    },
  },
};
</script>
