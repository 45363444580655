<template>
  <purchase-search></purchase-search>
</template>

<script>
import PurchaseSearch from "./PurchaseSearch.vue";
export default {
  name: "PurchaseArrivalPlanSearch",
  components: {
    PurchaseSearch,
  },
};
</script>
