<template>
  <v-container fluid style="height: 100%">
    <v-row no-gutters>
      <v-col>
        <v-btn icon v-if="infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-minimize</v-icon></v-btn
        >
        <v-btn icon v-if="!infoMaximum" @click="onInfoMaximum" class="float-right"
          ><v-icon>mdi-window-maximize</v-icon></v-btn
        >
        <v-tabs v-model="tab" centered grow class="mt-2 mb-2" @change="onActiveTabChanged" hide-slider>
          <v-tab href="#detail">{{ isCafereo ? "メーカー返品" : "返品" }}詳細</v-tab>
          <v-tab href="#purchaseDetail">{{ isCafereo ? "発注" : "受注" }}詳細</v-tab>
          <v-tab href="#history">履歴</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-tabs-items v-model="tab" id="tab-items">
          <v-tab-item id="detail">
            <purchase-return-details :style="detaleStyle" :details="details"></purchase-return-details>
          </v-tab-item>
          <v-tab-item id="purchaseDetail">
            <purchase-details :style="detaleStyle" :details="purchaseDetails"></purchase-details>
          </v-tab-item>
          <v-tab-item id="history">
            <purchase-return-history :returnCommitNumber="returnCommitNumber"></purchase-return-history>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PurchaseReturnDetails from "./PurchaseReturnDetails.vue";
import PurchaseReturnHistory from "./PurchaseReturnHistory.vue";
import PurchaseDetails from "./../purchase/PurchaseDetails.vue";
import { getDisplayDetailsValue } from "../../models/ag-grid/helpers";
import { Cafereo as CafereoColumnDefs, Maker as MakerColumnDefs } from "../../consts/columns/PurchaseColumns";
export default {
  name: "PurchaseReturnInfos",
  props: ["details", "isCafereo", "purchaseId", "returnCommitNumber"],
  data: () => ({
    tab: null,
    items: ["1", "2"],
    infoMaximum: false,
    purchaseDetails: [],
    detaleStyle: {
      height: window.innerHeight - 260 + "px",
      overflow: "scroll",
    },
  }),
  components: {
    PurchaseReturnDetails,
    PurchaseReturnHistory,
    PurchaseDetails,
  },
  watch: {
    purchaseId() {
      this.onActiveTabChanged();
    },
  },
  methods: {
    onInfoMaximum: function () {
      this.infoMaximum = !this.infoMaximum;
      this.$emit("infoMaximum-event");
    },
    async onActiveTabChanged() {
      if (this.tab == "purchaseDetail") {
        if (this.purchaseId) {
          try {
            this.loadingOn();
            let searchModel = {
              purchaseId: this.purchaseId,
            };
            const response = await this.$store.dispatch("purchaseReturn/purchasSearch", {
              searchConditions: searchModel,
            });

            this.purchaseDetails = getDisplayDetailsValue(
              this.isCafereo ? CafereoColumnDefs : MakerColumnDefs,
              this.isCafereo ? response.data.contents.purchases[0] : response.data.contents.orders[0]
            );
          } catch (error) {
            console.error("PurchaseReturnInfos::onActiveTabChanged", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        } else {
          this.purchaseDetails = [];
        }
      }
    },
    handleResize: function () {
      this.detaleStyle.height = window.innerHeight - 260 + "px";
    },
  },
  mounted: function () {
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
