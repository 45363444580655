<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title>
          <template v-if="allowedAction(constants.cafereoActions)"><v-icon>mdi-note-search</v-icon>発注一覧</template>
          <template v-else><v-icon>mdi-book-search</v-icon>受注管理</template>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-row dense>
              <v-checkbox class="mx-2" label="未出荷のみ" v-model="searchModel.unDeliveriedFlg" dense></v-checkbox>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="発注日From"
                  dense
                  type="date"
                  v-model="searchModel.purchaseOrderDateFrom"
                  :rules="[rules.purchaseOrderDateFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="発注日To"
                  dense
                  type="date"
                  v-model="searchModel.purchaseOrderDateTo"
                  :rules="[rules.purchaseOrderDateTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="納品日From"
                  dense
                  type="date"
                  v-model="searchModel.deliveryTimeFrom"
                  :rules="[rules.deliveryTimeFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="納品日To"
                  dense
                  type="date"
                  v-model="searchModel.deliveryTimeTo"
                  :rules="[rules.deliveryTimeTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-model="searchModel.jancodeList"
                  class="mx-2"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                  label="JANコード"
                  rows="1"
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-model="searchModel.productName"
                  class="mx-2"
                  :rules="[rules.maxLength(60)]"
                  label="商品名"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.releaseDateFrom"
                  type="date"
                  label="発売日From"
                  :rules="[rules.releaseDateFrom]"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-model="searchModel.releaseDateTo"
                  type="date"
                  label="発売日To"
                  :rules="[rules.releaseDateTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          icon="mdi-download"
          v-if="allowedAction(constants.csvActions) || allowedAction(constants.supplierActions)"
          @click="onBtnExport"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>
    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-tabs v-model="activeStatusTab" @change="onActiveStatusTabChanged">
          <v-tab href="#approved" style="width: 180px">
            <v-badge
              v-if="allowedAction(constants.supplierActions)"
              offset-x="-1"
              :content="approvedPurchaseCount"
              :value="approvedPurchaseCount"
              >納品日未確定</v-badge
            >
          </v-tab>
          <!-- <v-tab href="#shipped" style="width: 145px" v-if="isMakerUser">納品待ち</v-tab> -->
          <v-tab href="#shipped" style="width: 145px" v-if="allowedAction(constants.supplierActions)">検品待ち</v-tab>
          <v-tab href="#arrivaled" style="width: 145px" v-if="allowedAction(constants.supplierActions)">検品済み</v-tab>
        </v-tabs>
        <v-row>
          <v-col>
            <v-card elevation="1" class="mt-2" tile>
              <v-card-text class="font-weight-bold ml-4 mb-4">
                納品数合計：{{ this.purchaseQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2" tile>
              <v-card-text class="font-weight-bold ml-4 mb-4">
                発注数合計：{{ this.originPurchaseQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <ag-grid-vue
          id="PurchaseList"
          :style="gridStyle2"
          class="ag-theme-alpine"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="globalPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          rowSelection="multiple"
          :suppressCellSelection="false"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          @filter-changed="onfilterChanged"
          :getRowNodeId="(data) => data.purchaseId"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        :style="gridStyle"
        v-if="detailSelected"
        id="PurchaseInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <purchase-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :purchaseId="selectPurchaseId"
          :gridStyle="gridStyle2"
        ></purchase-infos>
      </v-col>
    </v-row>
    <v-dialog v-model="bulkMakerEditDialog" persistent scrollable>
      <purchase-maker-bulk-edit
        :inputModel="selectedRows"
        @onDialogClose="onPurchaseMakerBulkEditDialogClose"
        @onEditSubmit="onPurchaseMakerBulkEditSubmit"
        v-if="bulkMakerEditDialog"
      ></purchase-maker-bulk-edit>
    </v-dialog>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
    <bottom-right-attacher>
      <template v-if="allowedAction(constants.supplierActions)">
        <v-btn large color="primary" :disabled="!rowsSelected || activeStatusTab === 'arrivaled'" @click="onBtnAnswer">
          <v-icon>mdi-calendar</v-icon>納品情報を入力
        </v-btn>
      </template>
    </bottom-right-attacher>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import { LockedCellRenderer, ProtectCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import PurchaseTypes from "../../consts/PurchaseTypes";
import PurchaseInfos from "./../../components/purchase/PurchaseInfos1-5.vue";
import PurchaseMakerBulkEdit from "../../components/purchase/PurchaseMakerBulkEdit.vue";
import BottomRightAttacher from "../../components/common/BottomRightAttacher.vue";
import SearchConditions from "./../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import {
  Cafereo as CafereoColumnDefs,
  Maker1_5 as MakerColumnDefs,
  MakerAnswerConfirm1_5,
} from "../../consts/columns/PurchaseColumns";
import DateTypes from "../../consts/PurchaseImportDateTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import Validation from "../../libs/validation";
import "ag-grid-autocomplete-editor/dist/main.css";
import { NumericCellEditor } from "../../components/ag-grid/cellEditors";

export default {
  name: "PurchaseSearch1-5",
  components: {
    AgGridVue,
    PurchaseInfos,
    BottomRightAttacher,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ErrorGridDialog,
    PurchaseMakerBulkEdit,
  },
  data() {
    return {
      // 権限グループ
      constants: {
        cafereoActions: ["C030301"],
        createActions: ["C030302", "C030309"],
        updateActions: ["C030303", "C030310"],
        cancelActions: ["C030304"],
        createReturnActions: ["C030305"],
        printActions: ["C030306"],
        csvActions: ["C030307"],
        sanctionActions: ["C030308"],
        changeApprovalActions: ["C030309"],
        supplierActions: ["M020201", "M020202", "M020203", "M020204", "M020205", "M020206", "M020207"],
      },
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        isNumber: Validation.isNumber,
        isMinNumber: Validation.isMinNumber,
        isJancodeSearchList: Validation.isJancodeSearchList,
        purchaseOrderDateFrom: (value) => this.purchaseOrderDateFromRules(value),
        purchaseOrderDateTo: (value) => this.purchaseOrderDateToRules(value),
        deliveryTimeFrom: (value) => this.deliveryTimeFromRules(value),
        deliveryTimeTo: (value) => this.deliveryTimeToRules(value),
        releaseDateFrom: (value) => this.releaseDateFromRules(value),
        releaseDateTo: (value) => this.releaseDateToRules(value),
      },
      dateTypes: DateTypes,
      disabledPrint: false,
      validSeearchForm: null,
      activeStatusTab: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      rowsChangeSelected: null,
      infoMaximum: false,
      purchaseQuantity: 0,
      originPurchaseQuantity: 0,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      statedPurchases: null,
      selectedRow: null,
      selectedRows: [],
      deliverys: [],
      purchaseModel: {
        createNew: false,
      },
      editDialog: false,
      bulkEditDialog: false,
      bulkMakerEditDialog: false,
      importDialog: { show: false, width: "800px" },
      answerDialog: { show: false, width: "800px" },
      reserveDialog: { show: false, width: "800px" },
      warehouseNames: [],
      warehouses: {},
      answerConfirmColmuns: MakerAnswerConfirm1_5,
      errorColmuns: [
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      searchModel: {
        fetchLimitOverFlg: false,
        unDeliveriedFlg: true,
        purchaseOrderDateFrom: "",
        purchaseOrderDateTo: "",
        deliveryTimeFrom: "",
        deliveryTimeTo: "",
        jancodes: "",
        productName: "",
        salseAgencyName: "",
        salseOriginName: "",
        releaseDateFrom: "",
        releaseDateTo: "",
        cafereoRemarks: "",
        purchasePerson: "",
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpNumericCellEditor: NumericCellEditor,
      },
      gridStyle: { height: "95%", "flex-basis": "auto" },
      gridStyle2: { height: "78%" },
    };
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = this.setColumnDefs();
    this.rowData = [];
  },
  async mounted() {
    if (this.allowedAction(this.constants.cafereoActions)) {
      this.activeStatusTab = "unapproved";
    } else if (this.isArrivalPlanMode) {
      this.activeStatusTab = "all";
    } else {
      this.activeStatusTab = "unapproved";
    }
    this.$nextTick(function () {
      if (this.allowedAction(this.constants.supplierActions)) {
        this.onSearchClick();
      }
    });
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize + "px";
    if (this.globalPageSize > 10) {
      this.gridStyle2.height = this.gridHeightSize - 145 + "px";
    } else {
      this.gridStyle2.height = this.gridHeightSize - 145 + "px";
    }
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
      this.handleResize();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
      if (this.globalPageSize > 10) {
        this.gridStyle2.height = this.gridHeightSize - 145 + "px";
      } else {
        this.gridStyle2.height = this.gridHeightSize - 145 + "px";
      }
    },
  },
  computed: {
    approvedPurchaseCount() {
      return this.statedPurchases
        ? this.statedPurchases.unapproved.length +
            this.statedPurchases.approved.length +
            this.statedPurchases.answered.length
        : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      let detailColumns = this.columnApi
        .getAllColumns()
        .filter((column) => {
          switch (column.colDef.field) {
            case "deliveryZipCode":
            case "delivery1":
            case "delivery2":
            case "deliveryAddress":
            case "deliveryPhoneNumber":
              return true;
            default:
              return !column.colDef.hide;
          }
        })
        .map((column) => {
          let coldef = { ...column.colDef };
          return coldef;
        });
      return getDisplayDetails2(this.selectedRow.purchaseId, detailColumns, this.gridApi);
    },
    selectPurchaseId() {
      if (!this.selectedRow) {
        return null;
      }
      return this.selectedRow.purchaseId;
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    setColumnDefs() {
      let columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: (params) => {
            if (this.activeStatusTab === "unchangeApproved" && !params.data.productLocked) {
              return ProtectCellRenderer({ matcher: "purchaseLocked" })(params);
            } else {
              return LockedCellRenderer({ matcher: "purchaseLocked" })(params);
            }
          },
        },
      ];
      if (this.allowedAction(this.constants.cafereoActions)) {
        columnDefs = columnDefs.concat(CafereoColumnDefs);
      }
      if (this.allowedAction(this.constants.supplierActions)) columnDefs = columnDefs.concat(MakerColumnDefs);
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onActiveStatusTabChanged(activeTab) {
      console.log("activeTab::" + activeTab);
      if (this.gridApi && this.statedPurchases) {
        let coldefs = this.columnDefs;
        if (this.activeStatusTab === "shipped" || this.activeStatusTab === "arrivaled") {
          coldefs = this.columnDefs.map((column) => {
            if (column.field === "deliveryTime") column.hide = true;
            if (column.field === "deliveryReservation") column.hide = false;
            return column;
          });
        } else if (this.activeStatusTab === "approved" || this.activeStatusTab === "canceled") {
          coldefs = this.columnDefs.map((column) => {
            if (column.field === "deliveryTime") column.hide = false;
            if (column.field === "deliveryReservation") column.hide = true;
            return column;
          });
        }
        this.gridApi.setColumnDefs(coldefs);
        this.gridApi.paginationGoToFirstPage();
        if (this.allowedAction(this.constants.supplierActions) && this.activeStatusTab === "approved") {
          let rows = [];
          rows.push(...this.statedPurchases.unapproved);
          rows.push(...this.statedPurchases.approved);
          rows.push(...this.statedPurchases.answered);
          this.gridApi.setRowData(rows);
        } else {
          console.log("setRowData::");
          console.log(this.statedPurchases[activeTab]);
          this.gridApi.setRowData(this.statedPurchases[activeTab] || []);
        }
        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.columnApi.autoSizeColumns(allColumnIds);
        this.onfilterChanged();
      }
      this.selectedRow = null;
      this.selectedRows = [];
      this.rowsSelected = false;
      this.rowsChangeSelected = false;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = "受注一覧.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected =
        this.selectedRows.filter((row) => {
          return !row.purchaseLocked;
        }).length > 0;
      this.rowsChangeSelected =
        this.selectedRows.filter((row) => {
          return !row.productLocked;
        }).length > 0;
      this.disabledPrint =
        this.selectedRows.filter((row) => {
          return row.purchaseType == PurchaseTypes.PURCHASE;
        }).length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;

      if (this.selectedRows.length === 1) {
        this.selectedRow = this.selectedRows[0];
      } else {
        this.selectedRow = null;
      }
    },
    onfilterChanged() {
      this.purchaseQuantity = 0;
      this.originPurchaseQuantity = 0;
      this.gridApi.forEachNodeAfterFilter((row) => {
        if (row.displayed) {
          if (row.data.purchaseQuantity) {
            this.purchaseQuantity += row.data.purchaseQuantity;
          }
          if (row.data.originPurchaseQuantity) {
            this.originPurchaseQuantity += row.data.originPurchaseQuantity;
          }
        }
      });
    },
    onPurchaseMakerBulkEditDialogClose(rows) {
      this.bulkMakerEditDialog = false;
      if (rows.length > 0) this.onPurchaseMakerBulkEditSubmit(rows);
    },
    onPurchaseMakerBulkEditSubmit() {
      this.onSearchClick();
      this.bulkMakerEditDialog = false;
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    // 検索処理
    async onSearchClick() {
      try {
        this.loadingOn();
        // jancodeのリスト化
        this.searchModel.jancode = this.searchModel.jancodeList
          ? this.searchModel.jancodeList
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        if (this.searchModel.unDeliveriedFlg) {
          this.searchModel.purchaseStatuses = [1, 2, 3, 4, 5];
        } else {
          this.searchModel.purchaseStatuses = null;
        }
        const response = await this.$store.dispatch("purchase/search", { searchConditions: this.searchModel });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = this.allowedAction(this.constants.supplierActions) ? "受注管理" : "発注一覧";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }

        let title = "";
        let tmpContents = null;
        if (this.allowedAction(this.constants.supplierActions)) {
          title = "受注管理";
          tmpContents = result.orders;
        } else {
          title = "発注一覧";
          tmpContents = result.purchases;
        }
        if (Object.keys(tmpContents).length === 0) {
          this.$dialog.warning({
            title: title,
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.statedPurchases = response.data.contents.statedPurchases || {};
        if (this.activeStatusTab === "approved") {
          let rows = [];
          rows.push(...this.statedPurchases.unapproved);
          rows.push(...this.statedPurchases.approved);
          rows.push(...this.statedPurchases.answered);
          this.gridApi.setRowData(rows);
        } else {
          this.gridApi.setRowData(this.statedPurchases[this.activeStatusTab] || []);
        }

        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.columnApi.autoSizeColumns(allColumnIds);
        this.onfilterChanged();
      } catch (error) {
        console.error("PurchaseSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onBtnAnswer() {
      this.bulkMakerEditDialog = true;
    },
    purchaseOrderDateFromRules(value) {
      if (value == null || this.searchModel.purchaseOrderDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.purchaseOrderDateTo)) return "発注日Toより前の日付を指定してください";
      return true;
    },
    purchaseOrderDateToRules(value) {
      if (value == null || this.searchModel.purchaseOrderDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.purchaseOrderDateFrom))
        return "発注日Fromより後の日付を指定してください";
      return true;
    },
    deliveryTimeFromRules(value) {
      if (value == null || this.searchModel.deliveryTimeTo == null) return true;
      if (moment(value).isAfter(this.searchModel.deliveryTimeTo)) return "納品日Toより前の日付を指定してください";
      return true;
    },
    deliveryTimeToRules(value) {
      if (value == null || this.searchModel.deliveryTimeFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.deliveryTimeFrom)) return "納品日Fromより後の日付を指定してください";
      return true;
    },
    releaseDateFromRules(value) {
      if (value == null || this.searchModel.releaseDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.releaseDateTo)) return "発売日Toより前の日付を指定してください";
      return true;
    },
    releaseDateToRules(value) {
      if (value == null || this.searchModel.releaseDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.releaseDateFrom)) return "発売日Fromより後の日付を指定してください";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
  },
};
</script>
