import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "発注数", enum: "PURCHASE_QUANTITY" },
  { value: 2, text: "仕入価格", enum: "UNIT_PRICE" },
  { value: 3, text: "納品予定日", enum: "DELIVERY_TIME" },
  { value: 4, text: "納品予約", enum: "DELIVERY_RESERVATION" },
  { value: 5, text: "配送業者", enum: "DELIVERY_COMPANY" },
  { value: 6, text: "備考(メーカー表示)", enum: "MAKER_REMARKS" },
  { value: 7, text: "カフェレオ担当者", enum: "PURCHASE_PERSON" },
  { value: 8, text: "メーカー担当者", enum: "MAKER_PERSON" },
  { value: 9, text: "社内備考1", enum: "CAFEREO_REMARKS" },
]);
