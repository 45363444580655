<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title>
          <template v-if="allowedAction(constants.cafereoActions)"><v-icon>mdi-note-search</v-icon>発注一覧</template>
          <template v-else><v-icon>mdi-book-search</v-icon>受注管理</template>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-row dense>
              <v-checkbox
                v-if="isCafereoUser"
                class="mx-2"
                label="検索上限無し"
                v-model="searchModel.fetchLimitOverFlg"
                dense
              ></v-checkbox>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  :label="isCafereoUser ? '発注日From' : '受注日From'"
                  dense
                  type="date"
                  v-model="searchModel.purchaseOrderDateFrom"
                  :rules="[rules.purchaseOrderDateFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  :label="isCafereoUser ? '発注日To' : '受注日To'"
                  dense
                  type="date"
                  v-model="searchModel.purchaseOrderDateTo"
                  :rules="[rules.purchaseOrderDateTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="納品予約From"
                  dense
                  type="date"
                  v-model="searchModel.deliveryReservationFrom"
                  :rules="[rules.deliveryReservationFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="納品予約To"
                  dense
                  type="date"
                  v-model="searchModel.deliveryReservationTo"
                  :rules="[rules.deliveryReservationTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="回答日From"
                  dense
                  type="date"
                  v-model="searchModel.responseDateFrom"
                  :rules="[rules.responseDateFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="回答日To"
                  dense
                  type="date"
                  v-model="searchModel.responseDateTo"
                  :rules="[rules.responseDateTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.purchaseNo"
                  class="mx-2"
                  :rules="[rules.maxLength(12)]"
                  label="発注番号"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.supplierShortName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="仕入先略称"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-if="isCafereoUser"
                  v-model="searchModel.jancodeList"
                  class="mx-2"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                  label="JANコード"
                  rows="1"
                  dense
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.productName"
                  class="mx-2"
                  :rules="[rules.maxLength(60)]"
                  label="商品名"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.salseAgencyName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="発売元"
                  dense
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-if="isCafereoUser"
                  v-model="searchModel.salseOriginName"
                  class="mx-2"
                  :rules="[rules.maxLength(150)]"
                  label="販売元"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  v-if="isCafereoUser"
                  v-model="searchModel.releaseDateFrom"
                  type="date"
                  label="発売日From"
                  :rules="[rules.releaseDateFrom]"
                  dense
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  v-if="isCafereoUser"
                  v-model="searchModel.releaseDateTo"
                  type="date"
                  label="発売日To"
                  :rules="[rules.releaseDateTo]"
                  dense
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  v-if="isCafereoUser"
                  v-model="searchModel.cafereoRemarks"
                  class="mx-2"
                  :rules="[rules.maxLength(256)]"
                  label="カフェレオ備考"
                  rows="1"
                  dense
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <cafereo-user-field
                  ref="purchasePerson"
                  label="担当者"
                  :value="searchModel.customerStaff"
                  v-model="searchModel.customerStaff"
                  v-if="allowedAction(constants.cafereoActions)"
                  :attach="false"
                ></cafereo-user-field>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <template>
          <template v-if="allowedAction(constants.createActions)">
            <tooltip-icon-button icon="mdi-pencil-plus-outline" @click="onBtnInsert">新規登録</tooltip-icon-button>
            <v-divider vertical></v-divider>
          </template>
        </template>
        <template v-if="allowedAction(constants.createActions)">
          <tooltip-icon-button icon="mdi-database-import" @click="onBtnImport">一括取り込み</tooltip-icon-button>
        </template>
        <template v-if="allowedAction(constants.supplierActions) || allowedAction(constants.updateActions)">
          <tooltip-icon-button
            :disabled="
              !selectedRow ||
              (allowedAction(constants.supplierActions) &&
                activeStatusTab == 'arrivaled' &&
                selectedRow.purchaseStatus == 6) ||
              activeStatusTab == 'canceled' ||
              selectedRow.purchaseLocked
            "
            icon="mdi-pencil-outline"
            @click="onBtnUpdate"
            >編集</tooltip-icon-button
          >
        </template>
        <v-dialog v-model="editDialog" max-width="1800px" persistent scrollable>
          <purchase-entry-form
            :inputModel="purchaseModel"
            @onDialogClose="onPurchaseDialogClose"
            @onEntrySubmit="onPurchaseEntrySubmit"
            @onEditSubmit="onPurchaseEditSubmit"
            v-if="editDialog"
          ></purchase-entry-form>
        </v-dialog>
        <v-dialog v-model="importDialog.show" :max-width="importDialog.width" persistent scrollable>
          <purchase-import
            @complete="onImportComplete"
            @expand="importDialog.width = $event ? '100%' : '800px'"
            v-if="importDialog.show"
          ></purchase-import>
        </v-dialog>
        <template v-if="allowedAction(constants.updateActions)">
          <tooltip-icon-button
            :disabled="
              selectedRows.length == 0 ||
              activeStatusTab == 'canceled' ||
              selectedRows.findIndex((data) => data.purchaseLocked) > -1
            "
            icon="mdi-database-edit"
            @click="onBtnBulkEdit"
            >一括編集</tooltip-icon-button
          >
        </template>
        <v-dialog v-model="bulkEditDialog" max-width="1800px" persistent scrollable>
          <purchase-bulk-edit
            :inputModel="selectedRows"
            @onDialogClose="onPurchaseBulkEditDialogClose"
            @onEditSubmit="onPurchaseBulkEditSubmit"
            v-if="bulkEditDialog"
          ></purchase-bulk-edit>
        </v-dialog>
        <template>
          <tooltip-icon-button
            v-if="allowedAction(constants.cancelActions)"
            :disabled="
              selectedRows.length == 0 ||
              !(
                activeStatusTab == 'unsanction' ||
                activeStatusTab == 'sanctionRejected' ||
                activeStatusTab == 'unapproved' ||
                activeStatusTab == 'approved' ||
                activeStatusTab == 'answered' ||
                activeStatusTab == 'shipped' ||
                activeStatusTab == 'rejected'
              ) ||
              !rowsSelected
            "
            icon="mdi-pencil-remove-outline"
            @click="onBtnCancel"
            >キャンセル</tooltip-icon-button
          >
        </template>
        <tooltip-icon-button
          icon="mdi-sync"
          v-if="allowedAction(constants.createReturnActions)"
          :disabled="selectedRows.length == 0 || activeStatusTab != 'arrivaled'"
          @click="onBtnPurchaseReturnEntry"
          >メーカー返品登録</tooltip-icon-button
        >
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          v-if="allowedAction(constants.printActions) || allowedAction(constants.supplierActions)"
          icon="mdi-book-arrow-down"
          @click="onBtnPurchaseOrderFormPrint"
          :disabled="!disabledPrint"
          >発注書出力</tooltip-icon-button
        >
        <tooltip-icon-button
          icon="mdi-download"
          v-if="allowedAction(constants.csvActions) || allowedAction(constants.supplierActions)"
          @click="onBtnExport"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>
    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-tabs v-model="activeStatusTab" @change="onActiveStatusTabChanged">
          <v-tab href="#unsanction" style="width: 145px" v-if="allowedAction(constants.cafereoActions)">
            <v-badge offset-x="-1" :content="unsanctionPurchaseCount" :value="unsanctionPurchaseCount"
              >決裁待ち</v-badge
            >
          </v-tab>
          <v-tab href="#sanctionRejected" style="width: 145px" v-if="allowedAction(constants.cafereoActions)">
            <v-badge offset-x="-1" :content="sanctionRejectedPurchaseCount" :value="sanctionRejectedPurchaseCount"
              >決裁却下</v-badge
            >
          </v-tab>
          <v-tab href="#unchangeApproved" style="width: 145px">
            <template v-if="allowedAction(constants.supplierActions)">変更承認待ち</template>
            <v-badge
              v-if="allowedAction(constants.cafereoActions)"
              offset-x="-1"
              :content="unchangeApprovedPurchaseCount"
              :value="unchangeApprovedPurchaseCount"
              >変更承認待ち</v-badge
            >
          </v-tab>
          <v-tab href="#unapproved" style="width: 145px">
            <v-badge
              v-if="allowedAction(constants.supplierActions)"
              offset-x="-1"
              :content="unapprovedPurchaseCount"
              :value="unapprovedPurchaseCount"
              >承認待ち</v-badge
            >
            <template v-if="allowedAction(constants.cafereoActions)">承認待ち</template>
          </v-tab>
          <v-tab href="#approved" style="width: 180px">
            <v-badge
              v-if="allowedAction(constants.supplierActions)"
              offset-x="-1"
              :content="approvedPurchaseCount"
              :value="approvedPurchaseCount"
              >納品予定日回答待ち</v-badge
            >
            <template v-if="allowedAction(constants.cafereoActions)">納品予定日回答待ち</template>
          </v-tab>
          <v-tab href="#answered" style="width: 180px">
            <v-badge
              v-if="allowedAction(constants.supplierActions)"
              offset-x="-1"
              :content="answeredPurchaseCount"
              :value="answeredPurchaseCount"
              >納品予約待ち</v-badge
            >
            <template v-if="allowedAction(constants.cafereoActions)">納品予約待ち</template>
          </v-tab>
          <!-- <v-tab href="#shipped" style="width: 145px" v-if="isMakerUser">納品待ち</v-tab> -->
          <v-tab href="#shipped" style="width: 145px" v-if="allowedAction(constants.cafereoActions)">入荷待ち</v-tab>
          <v-tab href="#arrivaled" style="width: 145px" v-if="allowedAction(constants.supplierActions)">納品済み</v-tab>
          <v-tab href="#arrivaled" style="width: 145px" v-if="allowedAction(constants.cafereoActions)">入荷済み</v-tab>
          <v-tab href="#canceled" style="width: 180px" v-if="allowedAction(constants.cafereoActions)">削除</v-tab>
        </v-tabs>
        <v-row v-if="allowedAction(constants.cafereoActions)">
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                発注数合計：{{ this.purchaseQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                仕入金額合計：￥{{ this.purchaseAmount.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                在庫数合計：{{ this.stockQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                在庫金額合計：￥{{ this.stockAmount.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row v-if="allowedAction(constants.supplierActions)">
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                販売価格合計：￥{{ this.salesAmountTotal.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <ag-grid-vue
          id="PurchaseList"
          :style="gridStyle2"
          class="ag-theme-alpine"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="globalPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          rowSelection="multiple"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          @filter-changed="onfilterChanged"
          :getRowNodeId="(data) => data.purchaseId"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        :style="gridStyle"
        v-if="detailSelected"
        id="PurchaseInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <purchase-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :purchaseId="selectPurchaseId"
          :gridStyle="gridStyle2"
        ></purchase-infos>
      </v-col>
    </v-row>
    <confirm-grid-dialog
      ref="sanctionConfirmGrid"
      width="80%"
      height="80%"
      icon="mdi-check"
      title="決裁"
      :autoClose="false"
      :columns="sanctionConfirmColmuns"
    ></confirm-grid-dialog>
    <confirm-grid-dialog
      ref="changeApprovalConfirmGrid"
      width="80%"
      height="80%"
      icon="mdi-check"
      title="変更承認"
      :autoClose="false"
      :columns="approvalChangeConfirmColmuns"
    ></confirm-grid-dialog>
    <confirm-grid-dialog
      ref="approvalConfirmGrid"
      width="80%"
      height="80%"
      icon="mdi-check"
      title="受注承認"
      :autoClose="false"
      :columns="approvalConfirmColmuns"
    ></confirm-grid-dialog>
    <datepicker-confirm-grid-dialog
      ref="answerConfirmGrid"
      width="80%"
      height="400px"
      icon="mdi-calendar-check"
      title="納品予定日回答"
      :autoClose="false"
      :columns="answerConfirmColmuns"
    ></datepicker-confirm-grid-dialog>
    <datepicker-confirm-grid-dialog
      ref="reserveConfirmGrid"
      width="80%"
      height="400px"
      icon="mdi-calendar-check"
      title="納品予約"
      :autoClose="false"
      :columns="reserveConfirmColmuns"
      :abountDate="false"
    ></datepicker-confirm-grid-dialog>
    <confirm-grid-dialog
      ref="purchaseReturnEntry"
      width="80%"
      height="80%"
      icon="mdi-calendar-check"
      title="メーカー返品登録"
      btnSubmit="登録"
      :autoClose="false"
      :columns="purchaseReturnEntryColmuns"
    ></confirm-grid-dialog>
    <v-dialog v-model="answerDialog.show" :max-width="answerDialog.width" persistent scrollable>
      <maker-import
        :PurchaseModel="statedPurchases.approved"
        :DateType="dateTypes.ANSWER"
        @complete="onAnswerImportSubmit"
        @cancel="onAnswerImportDialogClose"
        @expand="answerDialog.width = $event ? '100%' : '800px'"
        v-if="answerDialog.show"
      ></maker-import>
    </v-dialog>
    <v-dialog v-model="reserveDialog.show" :max-width="reserveDialog.width" persistent scrollable>
      <maker-import
        :PurchaseModel="statedPurchases.answered"
        :DateType="dateTypes.RESERVE"
        @complete="onReserveImportSubmit"
        @cancel="onReserveImportDialogClose"
        @expand="reserveDialog.width = $event ? '100%' : '800px'"
        v-if="reserveDialog.show"
      ></maker-import>
    </v-dialog>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
    <bottom-right-attacher>
      <template v-if="allowedAction(constants.cafereoActions)">
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'unsanction' && allowedAction(constants.sanctionActions)"
          @click="onBtnSanction"
        >
          <v-icon>mdi-check</v-icon>決裁
        </v-btn>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'unchangeApproved' && allowedAction(constants.changeApprovalActions)"
          :disabled="!rowsChangeSelected"
          @click="onBtnChangeApproval"
        >
          <v-icon>mdi-check</v-icon>変更承認
        </v-btn>
      </template>
      <template v-if="allowedAction(constants.supplierActions)">
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'unapproved'"
          :disabled="!rowsSelected"
          @click="onBtnApproval"
        >
          <v-icon>mdi-check</v-icon>承認
        </v-btn>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'approved'"
          @click="onBtnAnswerImport"
          style="margin-right: 5px"
        >
          <v-icon>mdi-calendar</v-icon>納品予定日回答取込
        </v-btn>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'approved'"
          :disabled="!rowsSelected"
          @click="onBtnAnswer"
        >
          <v-icon>mdi-calendar</v-icon>納品予定日回答
        </v-btn>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'answered'"
          @click="onBtnReserveImport"
          style="margin-right: 5px"
        >
          <v-icon>mdi-calendar-check</v-icon>納品予約取込
        </v-btn>
        <v-btn
          large
          color="primary"
          v-if="activeStatusTab === 'answered'"
          :disabled="!rowsSelected"
          @click="onBtnReserve"
        >
          <v-icon>mdi-calendar-check</v-icon>納品予約
        </v-btn>
      </template>
    </bottom-right-attacher>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import { BooleanCellRenderer, LockedCellRenderer, ProtectCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import PurchaseTypes from "../../consts/PurchaseTypes";
import MakerImport from "./../../components/purchase/MakerImport.vue";
import PurchaseInfos from "./../../components/purchase/PurchaseInfos.vue";
import PurchaseEntryForm from "../../components/purchase/PurchaseEntryForm.vue";
import PurchaseBulkEdit from "../../components/purchase/PurchaseBulkEdit.vue";
import PurchaseImport from "../../components/purchase/PurchaseImport.vue";
import BottomRightAttacher from "../../components/common/BottomRightAttacher.vue";
import SearchConditions from "./../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import DatepickerConfirmGridDialog from "../../components/common/DatepickerConfirmGridDialog.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import productConditionTypes from "../../consts/productConditionTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";
import {
  Cafereo as CafereoColumnDefs,
  Maker as MakerColumnDefs,
  MakerAnswerConfirm,
  MakerReserveConfirm,
} from "../../consts/columns/PurchaseColumns";
import DateTypes from "../../consts/PurchaseImportDateTypes";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import Validation from "../../libs/validation";
import RequestUtils from "./../../utils/RequestUtils";
import { AutocompleteSelectCellEditor } from "ag-grid-autocomplete-editor";
import "ag-grid-autocomplete-editor/dist/main.css";

export default {
  name: "PurchaseSearch",
  components: {
    AgGridVue,
    PurchaseInfos,
    PurchaseEntryForm,
    BottomRightAttacher,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ConfirmGridDialog,
    ErrorGridDialog,
    CafereoUserField,
    DatepickerConfirmGridDialog,
    MakerImport,
    PurchaseBulkEdit,
    PurchaseImport,
  },
  data() {
    return {
      // 権限グループ
      constants: {
        cafereoActions: ["C030301"],
        createActions: ["C030302", "C030309"],
        updateActions: ["C030303", "C030310"],
        cancelActions: ["C030304"],
        createReturnActions: ["C030305"],
        printActions: ["C030306"],
        csvActions: ["C030307"],
        sanctionActions: ["C030308"],
        changeApprovalActions: ["C030309"],
        supplierActions: ["M020201", "M020202", "M020203", "M020204", "M020205", "M020206", "M020207"],
      },
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        isNumber: Validation.isNumber,
        isMinNumber: Validation.isMinNumber,
        isJancodeSearchList: Validation.isJancodeSearchList,
        purchaseOrderDateFrom: (value) => this.purchaseOrderDateFromRules(value),
        purchaseOrderDateTo: (value) => this.purchaseOrderDateToRules(value),
        deliveryReservationFrom: (value) => this.deliveryReservationFromRules(value),
        deliveryReservationTo: (value) => this.deliveryReservationToRules(value),
        releaseDateFrom: (value) => this.releaseDateFromRules(value),
        releaseDateTo: (value) => this.releaseDateToRules(value),
        responseDateFrom: (value) => this.responseDateFromRules(value),
        responseDateTo: (value) => this.responseDateToRules(value),
      },
      dateTypes: DateTypes,
      disabledPrint: false,
      validSeearchForm: null,
      activeStatusTab: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      rowsChangeSelected: null,
      infoMaximum: false,
      purchaseQuantity: 0,
      purchaseAmount: 0,
      stockQuantity: 0,
      stockAmount: 0,
      salesAmountTotal: 0,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      statedPurchases: null,
      selectedRow: null,
      selectedRows: [],
      deliverys: [],
      purchaseModel: {
        createNew: false,
      },
      editDialog: false,
      bulkEditDialog: false,
      importDialog: { show: false, width: "800px" },
      answerDialog: { show: false, width: "800px" },
      reserveDialog: { show: false, width: "800px" },
      warehouseNames: [],
      warehouses: {},
      sanctionConfirmColmuns: [
        {
          headerName: "承認",
          field: "doApproval",
          pinned: "left",
          cellRenderer: BooleanCellRenderer("mdi-check-circle", "mdi-close"),
        },
        { headerName: "発注ID", field: "purchaseId", pinned: "left" },
        { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "受注数", field: "purchaseQuantity", type: "dpNumericColumn" },
        { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
      ],
      approvalConfirmColmuns: [
        {
          headerName: "承認",
          field: "doApproval",
          pinned: "left",
          cellRenderer: BooleanCellRenderer("mdi-check-circle", "mdi-close"),
        },
        { headerName: "発注ID", field: "purchaseId", pinned: "left" },
        { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "受注数", field: "purchaseQuantity", type: "dpNumericColumn" },
        { headerName: "受注単位", field: "unit", type: "dpNumericColumn" },
        { headerName: "上限数", field: "maximumQuantity", type: "dpNumericColumn" },
        { headerName: "直送依頼", field: "directDeliveryRequestFlg", type: "dpCheckmarkColumn" },
      ],
      approvalChangeConfirmColmuns: [
        {
          headerName: "承認",
          field: "doApproval",
          pinned: "left",
          cellRenderer: BooleanCellRenderer("mdi-check-circle", "mdi-close"),
        },
        { headerName: "発注ID", field: "purchaseId", pinned: "left" },
        { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        { headerName: "発注数", field: "purchaseQuantity", type: "dpNumericColumn" },
        { headerName: "仕入合計金額", field: "purchasePriceTotal", type: "dpNumericColumn" },
      ],
      answerConfirmColmuns: MakerAnswerConfirm,
      reserveConfirmColmuns: MakerReserveConfirm,
      purchaseReturnEntryColmuns: [
        { headerName: "発注ID", field: "purchaseId", pinned: "left" },
        { headerName: "発注日", field: "purchaseOrderDate", type: "dpDateColumn" },
        { headerName: "JANCODE", field: "janCode" },
        { headerName: "商品名", field: "productName" },
        { headerName: "発注数", field: "purchaseQuantity", type: "dpNumericColumn" },
        { headerName: "単位", field: "unit" },
        {
          headerName: "返品数",
          field: "returnQuantity",
          type: "dpNumericColumn",
          editable: true,
          cellStyle: { backgroundColor: "#FFEDB3" },
        },
        {
          headerName: "商品状態区分",
          field: "productConditionType",
          editable: true,
          cellStyle: { backgroundColor: "#FFEDB3" },
          cellEditor: "agSelectCellEditor",
          cellEditorParams: (params) => {
            if (
              params.data.purchaseType == PurchaseTypes.ENTRUST ||
              params.data.purchaseType == PurchaseTypes.DIGESTIVE
            ) {
              return {
                values: [productConditionTypes.of(productConditionTypes.BAD)],
              };
            }
            return {
              values: productConditionTypes.all(false).map((conditionType) => {
                return conditionType.text;
              }),
            };
          },
        },
        {
          headerName: "倉庫",
          field: "warehouseCd",
          editable: true,
          cellStyle: { backgroundColor: "#FFEDB3" },
          cellEditor: "agSelectCellEditor",
          cellEditorParams: () => ({ values: this.warehouseNames }),
        },
        {
          field: "supplierDeliveryCode",
          headerName: "配送先",
          width: 265,
          cellEditor: AutocompleteSelectCellEditor,
          cellStyle: { backgroundColor: "#FFEDB3" },
          editable: true,
          cellEditorParams: (params) => {
            return {
              selectData: this.deliverys.find((delivery) => delivery.purchaseId == params.data.purchaseId).deliverys,
              placeholder: "配送先入力",
              required: true,
              autocomplete: {
                showOnFocus: true,
              },
            };
          },
          valueFormatter: (params) => {
            if (params.value) {
              return params.value.label || params.value.value || params.value;
            }
            return "";
          },
        },
      ],
      errorColmuns: [
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      searchModel: {
        fetchLimitOverFlg: false,
        purchaseOrderDateFrom: "",
        purchaseOrderDateTo: "",
        deliveryReservationFrom: "",
        deliveryReservationTo: "",
        jancodes: "",
        productName: "",
        salseAgencyName: "",
        salseOriginName: "",
        releaseDateFrom: "",
        releaseDateTo: "",
        responseDateFrom: "",
        responseDateTo: "",
        cafereoRemarks: "",
        purchasePerson: "",
        supplierShortName: "",
        purchaseNo: "",
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
      },
      gridStyle: { height: "95%", "flex-basis": "auto" },
      gridStyle2: { height: "80%" },
    };
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = this.setColumnDefs();
    this.rowData = [];
  },
  async mounted() {
    if (this.allowedAction(this.constants.cafereoActions)) {
      this.activeStatusTab = "unapproved";
    } else if (this.isArrivalPlanMode) {
      this.activeStatusTab = "all";
    } else {
      this.activeStatusTab = "unapproved";
    }
    if (this.allowedAction(this.constants.cafereoActions)) {
      const response = await this.$store.dispatch("stock/storageSearch");
      if (response.data?.contents?.storage) {
        const storages = response.data.contents.storage;
        storages.forEach((storage) => {
          this.warehouseNames.push(storage.warehouseName);
          this.warehouses[storage.warehouseName] = storage.warehouseCd;
        });
      }
    }
    this.$nextTick(function () {
      if (this.allowedAction(this.constants.supplierActions)) {
        this.searchModel.purchaseOrderDateFrom = moment().add(-1, "months").format("YYYY-MM-DD");
        this.onSearchClick();
      }
    });
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize + "px";
    if (this.globalPageSize > 10) {
      this.gridStyle2.height = this.gridHeightSize - 135 + "px";
    } else {
      this.gridStyle2.height = this.gridHeightSize - 135 + "px";
    }
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
      this.handleResize();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
      if (this.globalPageSize > 10) {
        this.gridStyle2.height = this.gridHeightSize - 135 + "px";
      } else {
        this.gridStyle2.height = this.gridHeightSize - 135 + "px";
      }
    },
  },
  computed: {
    isArrivalPlanMode() {
      return this.$route.name === "PurchaseArrivalPlanSearch";
    },
    unsanctionPurchaseCount() {
      return this.statedPurchases && this.statedPurchases.unsanction ? this.statedPurchases.unsanction.length : null;
    },
    sanctionRejectedPurchaseCount() {
      return this.statedPurchases && this.statedPurchases.sanctionRejected
        ? this.statedPurchases.sanctionRejected.length
        : null;
    },
    unchangeApprovedPurchaseCount() {
      return this.statedPurchases && this.statedPurchases.unchangeApproved
        ? this.statedPurchases.unchangeApproved.length
        : null;
    },
    changeRejectedCount() {
      return this.statedPurchases && this.statedPurchases.changeRejected
        ? this.statedPurchases.changeRejected.length
        : null;
    },
    rejectedPurchaseCount() {
      return this.statedPurchases && this.statedPurchases.rejected ? this.statedPurchases.rejected.length : null;
    },
    unapprovedPurchaseCount() {
      return this.statedPurchases && this.statedPurchases.unapproved ? this.statedPurchases.unapproved.length : null;
    },
    approvedPurchaseCount() {
      return this.statedPurchases && this.statedPurchases.approved ? this.statedPurchases.approved.length : null;
    },
    answeredPurchaseCount() {
      return this.statedPurchases && this.statedPurchases.answered ? this.statedPurchases.answered.length : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      let detailColumns = this.columnApi
        .getAllColumns()
        .filter((column) => {
          switch (column.colDef.field) {
            case "deliveryZipCode":
            case "delivery1":
            case "delivery2":
            case "deliveryAddress":
            case "deliveryPhoneNumber":
              return true;
            default:
              return !column.colDef.hide;
          }
        })
        .map((column) => {
          let coldef = { ...column.colDef };
          return coldef;
        });
      return getDisplayDetails2(this.selectedRow.purchaseId, detailColumns, this.gridApi);
    },
    selectPurchaseId() {
      if (!this.selectedRow) {
        return null;
      }
      return this.selectedRow.purchaseId;
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    setColumnDefs() {
      let columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: (params) => {
            if (this.activeStatusTab === "unchangeApproved" && !params.data.productLocked) {
              return ProtectCellRenderer({ matcher: "purchaseLocked" })(params);
            } else {
              return LockedCellRenderer({ matcher: "purchaseLocked" })(params);
            }
          },
        },
      ];
      if (this.allowedAction(this.constants.cafereoActions)) {
        columnDefs = columnDefs.concat(CafereoColumnDefs);
      }
      if (this.allowedAction(this.constants.supplierActions)) columnDefs = columnDefs.concat(MakerColumnDefs);
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onActiveStatusTabChanged(activeTab) {
      console.log("activeTab::" + activeTab);
      if (this.gridApi && this.statedPurchases) {
        if (this.allowedAction(this.constants.supplierActions)) {
          if (this.activeStatusTab === "answered") {
            this.columnDefs[7].hide = false;
            this.columnDefs[8].hide = true;
          } else if (this.activeStatusTab === "arrivaled") {
            this.columnDefs[7].hide = true;
            this.columnDefs[8].hide = false;
          } else {
            this.columnDefs[7].hide = true;
            this.columnDefs[8].hide = true;
          }
        }
        this.gridApi.setColumnDefs(this.columnDefs);
        this.gridApi.paginationGoToFirstPage();
        if (this.allowedAction(this.constants.supplierActions) && this.activeStatusTab === "arrivaled") {
          let rows = [];
          rows.push(...this.statedPurchases.shipped);
          rows.push(...this.statedPurchases.arrivaled);
          this.gridApi.setRowData(rows);
        } else {
          console.log("setRowData::");
          console.log(this.statedPurchases[activeTab]);
          this.gridApi.setRowData(this.statedPurchases[activeTab] || []);
        }
        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.columnApi.autoSizeColumns(allColumnIds);
        this.purchaseQuantity = 0;
        this.purchaseAmount = 0;
        this.stockQuantity = 0;
        this.stockAmount = 0;
        this.salesAmountTotal = 0;
        for (let row of this.statedPurchases[this.activeStatusTab] || []) {
          if (row.purchaseQuantity) {
            this.purchaseQuantity += row.purchaseQuantity;
          }
          if (row.stockQuantity) {
            this.stockQuantity += row.stockQuantity;
          }
          if (row.stockPrice) {
            this.stockAmount += row.stockPrice;
          }
          if (row.purchaseQuantity && row.unitPrice) {
            this.purchaseAmount += row.purchaseQuantity * row.unitPrice;
            this.salesAmountTotal += row.purchaseQuantity * row.unitPrice;
          }
        }
      }
      this.selectedRow = null;
      this.selectedRows = [];
      this.rowsSelected = false;
      this.rowsChangeSelected = false;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = this.allowedAction(this.constants.supplierActions)
        ? "受注一覧.csv"
        : "発注一覧.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected =
        this.selectedRows.filter((row) => {
          return !row.purchaseLocked;
        }).length > 0;
      this.rowsChangeSelected =
        this.selectedRows.filter((row) => {
          return !row.productLocked;
        }).length > 0;
      this.disabledPrint =
        this.selectedRows.filter((row) => {
          return row.purchaseType == PurchaseTypes.PURCHASE;
        }).length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;

      if (this.selectedRows.length === 1) {
        this.selectedRow = this.selectedRows[0];
      } else {
        this.selectedRow = null;
      }
    },
    onfilterChanged() {
      this.purchaseQuantity = 0;
      this.purchaseAmount = 0;
      this.stockQuantity = 0;
      this.stockAmount = 0;
      this.salesAmountTotal = 0;
      this.gridApi.forEachNodeAfterFilter((row) => {
        if (row.displayed) {
          if (row.data.purchaseQuantity) {
            this.purchaseQuantity += row.data.purchaseQuantity;
          }
          if (row.data.stockQuantity) {
            this.stockQuantity += row.data.stockQuantity;
          }
          if (row.data.stockPrice) {
            this.stockAmount += row.data.stockPrice;
          }
          if (row.data.purchasePriceTotal) {
            this.purchaseAmount += row.data.purchasePriceTotal;
            this.salesAmountTotal += row.data.purchasePriceTotal;
          }
        }
      });
    },
    onBtnInsert() {
      this.purchaseModel = {
        createNew: true,
      };
      this.editDialog = true;
    },
    onBtnUpdate() {
      this.purchaseModel = {
        createNew: false,
        ...this.selectedRow,
      };
      this.editDialog = true;
    },
    onPurchaseDialogClose(redrawFlg) {
      this.editDialog = false;
      if (redrawFlg) this.onSearchClick();
    },
    async onPurchaseEntrySubmit() {
      this.editDialog = false;
      // ここでの検索は不要のはず INQ石田
      //this.onSearchClick();
    },
    async onPurchaseEditSubmit(updateModel) {
      this.editDialog = false;
      if (this.allowedAction(this.constants.cafereoActions)) {
        if (
          this.activeStatusTab == "unapproved" ||
          this.activeStatusTab == "approved" ||
          this.activeStatusTab == "answered" ||
          this.activeStatusTab == "shipped" ||
          this.activeStatusTab == "arrivaled"
        ) {
          const beforeList = [];
          beforeList.push(...this.statedPurchases[this.activeStatusTab]);
          this.statedPurchases[this.activeStatusTab] = [];
          for (let record of beforeList) {
            if (record.purchaseId == updateModel.purchaseId) {
              RequestUtils.pushPurchase(updateModel, this.statedPurchases);
            } else {
              RequestUtils.pushPurchase(record, this.statedPurchases);
            }
          }
          if (this.statedPurchases[this.activeStatusTab].some((elm) => elm.purchaseId == updateModel.purchaseId)) {
            this.gridApi.applyTransaction({ update: [updateModel] });
          } else {
            this.gridApi.applyTransaction({ remove: [updateModel] });
          }
          this.onSelectionChanged();
        } else {
          this.gridApi.applyTransaction({ remove: [updateModel] });
          const beforeList = [];
          this.statedPurchases[this.activeStatusTab].forEach((record) => {
            if (record.purchaseId == updateModel.purchaseId) {
              this.statedPurchases.unapproved.push(updateModel);
            } else {
              beforeList.push(record);
            }
          });
          this.statedPurchases[this.activeStatusTab] = beforeList;
          this.onSelectionChanged();
        }
      } else {
        this.gridApi.applyTransaction({ remove: [updateModel] });
        const beforeList = [];
        this.statedPurchases[this.activeStatusTab].forEach((record) => {
          if (record.purchaseId == updateModel.purchaseId) {
            this.statedPurchases.unchangeApproved.push(updateModel);
          } else {
            beforeList.push(record);
          }
        });
        this.statedPurchases[this.activeStatusTab] = beforeList;
        this.selectedRow = null;
      }
    },
    onBtnBulkEdit() {
      this.bulkEditDialog = true;
    },
    onBtnImport() {
      this.importDialog.show = true;
    },
    onImportComplete() {
      this.importDialog.show = false;
    },
    onPurchaseBulkEditDialogClose(rows) {
      this.bulkEditDialog = false;
      if (rows.length > 0) this.onPurchaseBulkEditSubmit(rows);
    },
    onPurchaseBulkEditSubmit(rows) {
      // 結果を一覧に反映
      rows.forEach((row) => {
        this.onPurchaseEditSubmit(row);
      });
      this.bulkEditDialog = false;
    },
    async onBtnCancel() {
      for (const row of this.selectedRows) {
        if (row.purchaseStatus == 7 && row.receiptScheduleDetailsNo) {
          await this.$dialog.warning({
            title: "注意",
            text: `翌日入荷データ出力されている発注が含まれています`,
            actions: ["OK"],
          });
          break;
        }
      }
      let messageText = `選択された発注をキャンセルします`;
      const ok = await this.$dialog.confirm({ title: "発注一覧", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.selectedRows.forEach((row) => {
            updateModels.push({
              purchaseId: row.purchaseId,
              lastUpdateDatetime: row.updateDate,
            });
          });
          var requestContents = {
            cancelConditions: updateModels,
          };
          const result = await this.$store.dispatch("purchase/cancel", requestContents);

          let error = result.data?.header;

          const afterRows = [];
          const removeRow = [];
          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    purchaseId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              if (result.data.contents?.purchases) {
                // 更新分のレコード変更
                this.statedPurchases[this.activeStatusTab].forEach((statusData) => {
                  let diffFlg = false;
                  result.data.contents.purchases.forEach((purchase) => {
                    if (purchase.purchaseId == statusData.purchaseId) {
                      statusData.purchaseStatus = purchase.status;
                      statusData.updateDate = purchase.updateDate;
                      this.statedPurchases.canceled.push(statusData);
                      removeRow.push(statusData);
                      diffFlg = true;
                    }
                  });
                  if (!diffFlg) afterRows.push(statusData);
                });
                this.statedPurchases[this.activeStatusTab] = afterRows;
              }
              if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "発注キャンセル", records: errorRows });
                this.$dialog.notify.error(`発注キャンセルに失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`発注がキャンセルされました`, { timeout: 2300 });
                this.$refs.reserveConfirmGrid.close();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseSearch::onBtnCancel", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    // 検索処理
    async onSearchClick() {
      try {
        this.loadingOn();
        // jancodeのリスト化
        this.searchModel.jancode = this.searchModel.jancodeList
          ? this.searchModel.jancodeList
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        const response = await this.$store.dispatch("purchase/search", { searchConditions: this.searchModel });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = this.allowedAction(this.constants.supplierActions) ? "受注管理" : "発注一覧";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }

        let title = "";
        let tmpContents = null;
        if (this.allowedAction(this.constants.supplierActions)) {
          title = "受注管理";
          tmpContents = result.orders;
        } else {
          title = "発注一覧";
          tmpContents = result.purchases;
        }
        if (Object.keys(tmpContents).length === 0) {
          this.$dialog.warning({
            title: title,
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.statedPurchases = response.data.contents.statedPurchases || {};
        if (this.isArrivalPlanMode) {
          let rows = [];
          rows.push(...this.statedPurchases.approved);
          rows.push(...this.statedPurchases.answered);
          rows.push(...this.statedPurchases.shipped);
          this.gridApi.setRowData(rows);
        } else {
          if (this.allowedAction(this.constants.supplierActions) && this.activeStatusTab === "arrivaled") {
            let rows = [];
            rows.push(...this.statedPurchases.shipped);
            rows.push(...this.statedPurchases.arrivaled);
            this.gridApi.setRowData(rows);
          } else {
            this.gridApi.setRowData(this.statedPurchases[this.activeStatusTab] || []);
          }
        }
        const allColumnIds = this.columnApi.getAllColumns().map((column) => {
          return column.colId;
        });
        this.columnApi.autoSizeColumns(allColumnIds);
        this.purchaseQuantity = 0;
        this.purchaseAmount = 0;
        this.stockQuantity = 0;
        this.stockAmount = 0;
        this.salesAmountTotal = 0;
        for (let row of this.statedPurchases[this.activeStatusTab] || []) {
          if (row.purchaseQuantity) {
            this.purchaseQuantity += row.purchaseQuantity;
          }
          if (row.stockQuantity) {
            this.stockQuantity += row.stockQuantity;
          }
          if (row.stockPrice) {
            this.stockAmount += row.stockPrice;
          }
          if (row.purchasePriceTotal) {
            this.purchaseAmount += row.purchasePriceTotal;
            this.salesAmountTotal += row.purchasePriceTotal;
          }
        }
      } catch (error) {
        console.error("PurchaseSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    async onBtnPurchaseReturnEntry() {
      this.loadingOn();
      let selectionRows = JSON.parse(JSON.stringify(this.gridApi.getSelectedRows()));
      this.deliverys = [];
      for (let i = 0; i < selectionRows.length; i++) {
        try {
          let rowDeliverys = [];
          const response = await this.$store.dispatch("deliveryDestination/searchDelivery", {
            groupCode: selectionRows[i].supplierCd,
          });
          if (!ApiStatus.isSystemError(response.data?.header)) {
            var deliverys = response.data.contents.deliveries;
            for (let i = 0; i < deliverys.length; i++) {
              const delivery = deliverys[i];
              const deliveryInfo = {
                value: delivery.deliveryCode,
                label: delivery.deliveryAddress,
              };
              rowDeliverys.push(deliveryInfo);
            }
            this.deliverys.push({ purchaseId: selectionRows[i].purchaseId, deliverys: rowDeliverys });
            if (rowDeliverys.length > 0) selectionRows[i].supplierDeliveryCode = rowDeliverys[0];
          }
        } catch (error) {
          console.error("OrderSearch::onBtnReturnEntry", error);
          this.apiRequestError(error);
        }
      }
      this.loadingOff();
      this.$refs.purchaseReturnEntry.open({
        records: selectionRows,
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            let errorRows = [];
            let isValid = true;
            records.forEach((row) => {
              var error = this.onValidPurchaseReturn(row);
              if (error != true) {
                errorRows.push({
                  purchaseId: row.purchaseId,
                  errorMessage: error,
                });
                isValid = false;
              }
              if (isValid) {
                updateModels.push({
                  purchaseId: row.purchaseId,
                  returnQuantity: Number(row.returnQuantity),
                  productConditionType: productConditionTypes.textOf(row.productConditionType).toString(),
                  warehouseCd: this.warehouses[row.warehouseCd],
                  deliveryCode: row.supplierDeliveryCode?.value,
                });
              }
            });
            const updateModel = {
              purchaseReturns: updateModels,
            };
            if (isValid) {
              const result = await this.$store.dispatch("purchaseReturn/entry", updateModel);

              let error = result.data?.header;
              const removeRow = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        purchaseId: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  records.forEach((row) => {
                    errorRows.forEach((error) => {
                      if (row.purchaseId != error.purchaseId) {
                        removeRow.push(row);
                      }
                    });
                  });
                  if (errorRows.length > 0) {
                    if (removeRow.length > 0)
                      this.$refs.purchaseReturnEntry.gridOptions.api.applyTransaction({ remove: removeRow });
                    this.$refs.bulkErrorGrid.open({ title: "メーカー返品登録", records: errorRows });
                    this.$dialog.notify.error(`メーカー返品登録に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`メーカー返品を登録しました`, { timeout: 2300 });
                    this.onSearchClick();
                    this.$refs.purchaseReturnEntry.close();
                  }
                  break;
                default:
                  this.redirectError();
                  break;
              }
            } else {
              this.$refs.bulkErrorGrid.open({ title: "メーカー返品登録", records: errorRows });
              this.$dialog.notify.error(`入力エラーが存在します。ご確認ください。`, {
                timeout: 2300,
              });
            }
          } catch (error) {
            console.error("PurchaseSearch::onBtnApproval", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    // 発注書印刷
    async onBtnPurchaseOrderFormPrint() {
      let messageText = `選択されたデータの発注書を印刷します。`;
      const ok = await this.$dialog.confirm({ title: "発注書印刷", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          const purchaseRows = this.gridApi.getSelectedRows();
          const purchases = [];
          purchaseRows.forEach((purchase) => {
            purchases.push(purchase.purchaseId);
          });

          var requestContents = {
            purchases: purchases,
          };
          const result = await this.$store.dispatch("purchase/print", requestContents);

          let error = result.data?.header;
          const errorRows = [];

          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
              window.open(result.data.contents.exportFilePath, "_blank");
              this.$dialog.notify.info(`発注書を印刷しました`, { timeout: 2300 });
              break;
            case ApiStatus.consts.BUSINESS_ERROR:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    purchaseId: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              this.$refs.bulkErrorGrid.open({ title: "発注書印刷", records: errorRows });
              this.$dialog.notify.error(`発注書印刷に失敗したデータが存在します。ご確認ください。`, {
                timeout: 2300,
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("PurchaseSearch::onBtnPurchaseOrderFormPrint", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    // 決裁処理
    async onBtnSanction() {
      const rows = [];
      let sanctionCount = 0;
      this.gridApi.forEachNode((node) => {
        if (!node.data.purchaseLocked) {
          rows.push({
            doApproval: node.selected,
            purchaseId: node.data.purchaseId,
            purchaseOrderDate: node.data.purchaseOrderDate,
            title: node.data.title,
            janCode: node.data.janCode,
            productName: node.data.productName,
            purchaseQuantity: node.data.purchaseQuantity,
            unit: node.data.unit,
            directDeliveryRequestFlg: node.data.directDeliveryRequest,
            updateDate: node.data.updateDate,
          });
          if (node.selected) {
            sanctionCount++;
          }
        }
      });
      this.$refs.sanctionConfirmGrid.open({
        records: rows,
        subtitle: `決裁: ${sanctionCount} 件 / 決裁却下: ${rows.length - sanctionCount} 件 / 全: ${rows.length} 件`,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            rows.forEach((row) => {
              updateModels.push({
                purchaseId: row.purchaseId,
                approvalFlg: row.doApproval,
                updateDatetime: row.updateDate,
              });
            });
            const updateModel = {
              sectionStatusConditions: updateModels,
            };
            const result = await this.$store.dispatch("purchase/decision", updateModel);

            let error = result.data?.header;

            const afterRows = [];
            const removeRow = [];
            const errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      purchaseId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 更新分のレコード変更
                if (result.data.contents?.purchases) {
                  this.statedPurchases.unsanction.forEach((unsanction) => {
                    let diffFlg = false;
                    result.data.contents.purchases.forEach((purchase) => {
                      if (purchase.purchaseId == unsanction.purchaseId) {
                        unsanction.purchaseStatus = purchase.status;
                        unsanction.updateDate = purchase.updateDate;
                        if (purchase.status == 2) {
                          this.statedPurchases.sanctionRejected.push(unsanction);
                        } else {
                          this.statedPurchases.unapproved.push(unsanction);
                        }

                        removeRow.push(unsanction);
                        diffFlg = true;
                      }
                    });
                    if (!diffFlg) afterRows.push(unsanction);
                  });
                  this.statedPurchases.unsanction = afterRows;
                }
                if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                if (errorRows.length > 0) {
                  if (removeRow.length > 0)
                    this.$refs.sanctionConfirmGrid.gridOptions.api.applyTransaction({ remove: removeRow });
                  this.$refs.bulkErrorGrid.open({ title: "発注決裁", records: errorRows });
                  this.$dialog.notify.error(`発注決裁処理に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`発注を決裁しました`, { timeout: 2300 });
                  this.$refs.sanctionConfirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("PurchaseSearch::onBtnApproval", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    // 変更承認処理
    async onBtnChangeApproval() {
      const rows = [];
      let sanctionCount = 0;
      this.gridApi.forEachNode((node) => {
        if (node.selected && !node.data.productLocked) {
          rows.push({
            doApproval: node.selected,
            purchaseId: node.data.purchaseId,
            purchaseOrderDate: node.data.purchaseOrderDate,
            janCode: node.data.janCode,
            title: node.data.title,
            productName: node.data.productName,
            purchaseQuantity: node.data.purchaseQuantity,
            purchasePriceTotal: node.data.purchasePriceTotal,
            directDeliveryRequestFlg: node.data.directDeliveryRequest,
            updateDate: node.data.updateDate,
          });
          sanctionCount++;
        }
      });
      this.$refs.changeApprovalConfirmGrid.open({
        records: rows,
        subtitle: `変更承認: ${sanctionCount} 件 / 全: ${rows.length} 件`,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            rows.forEach((row) => {
              updateModels.push({
                purchaseId: row.purchaseId,
                updateDatetime: row.updateDate,
              });
            });
            const updateModel = {
              purchases: updateModels,
            };
            const result = await this.$store.dispatch("purchase/changeApproval", updateModel);

            let error = result.data?.header;

            const afterRows = [];
            const removeRow = [];
            const errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      purchaseId: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 更新分のレコード変更
                if (result.data?.contents?.purchases) {
                  this.statedPurchases.unchangeApproved.forEach((unchangeApproved) => {
                    let diffFlg = false;
                    result.data.contents.purchases.forEach((purchase) => {
                      if (purchase.purchaseId == unchangeApproved.purchaseId) {
                        unchangeApproved.purchaseLocked = false;
                        unchangeApproved.updateDate = purchase.updateDate;
                        switch (purchase.status) {
                          case 3:
                            this.statedPurchases.unapproved.push(unchangeApproved);
                            break;
                          case 4:
                            this.statedPurchases.rejected.push(unchangeApproved);
                            break;
                          case 5:
                            this.statedPurchases.approved.push(unchangeApproved);
                            break;
                          case 6:
                            this.statedPurchases.answered.push(unchangeApproved);
                            break;
                          case 7:
                            this.statedPurchases.shipped.push(unchangeApproved);
                            break;
                          case 8:
                            this.statedPurchases.arrivaled.push(unchangeApproved);
                            break;
                          case 9:
                            this.statedPurchases.canceled.push(unchangeApproved);
                            break;
                        }
                        removeRow.push(unchangeApproved);
                        diffFlg = true;
                      }
                    });
                    if (!diffFlg) afterRows.push(unchangeApproved);
                  });
                  this.statedPurchases.unchangeApproved = afterRows;
                }
                if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                if (errorRows.length > 0) {
                  if (removeRow.length > 0) {
                    let removeRowGrid = [];
                    removeRow.forEach((rRow) => {
                      rows.forEach((row) => {
                        if (rRow.purchaseId == row.purchaseId) {
                          removeRowGrid.push(row);
                        }
                      });
                    });
                    this.$refs.changeApprovalConfirmGrid.gridOptions.api.applyTransaction({ remove: removeRowGrid });
                  }
                  this.$refs.bulkErrorGrid.open({ title: "発注変更承認", records: errorRows });
                  this.$dialog.notify.error(`発注変更承認処理に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`発注の変更を承認しました`, { timeout: 2300 });
                  this.$refs.changeApprovalConfirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("PurchaseSearch::onBtnApproval", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    // 承認処理
    async onBtnApproval() {
      const rows = [];
      let approvalCount = 0;
      this.gridApi.forEachNode((node) => {
        if (node.selected && !node.data.purchaseLocked) {
          rows.push({
            doApproval: node.selected,
            purchaseId: node.data.purchaseId,
            purchaseOrderDate: node.data.purchaseOrderDate,
            janCode: node.data.janCode,
            title: node.data.title,
            productName: node.data.productName,
            purchaseQuantity: node.data.purchaseQuantity,
            unit: node.data.unit,
            maximumQuantity: node.data.maximumQuantity,
            directDeliveryRequestFlg: node.data.directDeliveryRequestFlg,
            updateDate: node.data.updateDate,
          });
          approvalCount++;
        }
      });

      this.$refs.approvalConfirmGrid.open({
        records: rows,
        subtitle: `承認: ${approvalCount} 件 / 全: ${rows.length} 件`,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            rows.forEach((row) => {
              updateModels.push({
                purchaseId: row.purchaseId,
                approvalFlg: row.doApproval,
                updateDatetime: row.updateDate,
              });
            });
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("M020202", updateModels.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            // WAF対策で引数をすべてさきにAPIに送る
            // 3000件毎にスライス
            const sliceByNumber = (array, number) => {
              const length = Math.ceil(array.length / number);
              return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
            };
            let requestRecordsSplit = sliceByNumber(updateModels, 3000);
            let bulkId = null;
            // APIでマージして引数を保持
            for (let i = 0; i < requestRecordsSplit.length; i++) {
              var payloadParam = {
                orders: requestRecordsSplit[i],
                bulkId: bulkId,
              };
              console.log(payloadParam);
              const response = await this.$store.dispatch("purchase/bulkPayloadApproval", payloadParam);
              let error = response.data?.header;
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                  bulkId = response.data.contents.bulkId;
                  break;
                default:
                  this.redirectError();
                  break;
              }
            }
            let exec = true;
            let headers = null;
            let content = {
              bulkId: bulkId,
            };
            while (exec) {
              await new Promise((resolve) => setTimeout(resolve, 1000));
              const result = await this.$store.dispatch("purchase/approval", {
                contents: content,
                header: headers,
              });

              let error = result.data?.header;

              const afterRows = [];
              const removeRow = [];
              const errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  exec = false;
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        purchaseId: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 更新分のレコード変更
                  if (result.data?.contents?.orders) {
                    this.statedPurchases.unapproved.forEach((approval) => {
                      let diffFlg = false;
                      result.data.contents.orders.forEach((order) => {
                        if (order.purchaseId == approval.purchaseId) {
                          approval.purchaseStatus = order.status;
                          approval.updateDate = order.updateDate;
                          this.statedPurchases.approved.push(approval);
                          removeRow.push(approval);
                          diffFlg = true;
                        }
                      });
                      if (!diffFlg) afterRows.push(approval);
                    });
                  }
                  this.statedPurchases.unapproved = afterRows;
                  if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                  if (errorRows.length > 0) {
                    if (removeRow.length > 0) {
                      let removeRowGrid = [];
                      removeRow.forEach((rRow) => {
                        rows.forEach((row) => {
                          if (rRow.purchaseId == row.purchaseId) {
                            removeRowGrid.push(row);
                          }
                        });
                      });
                      this.$refs.approvalConfirmGrid.gridOptions.api.applyTransaction({ remove: removeRowGrid });
                    }
                    this.$refs.bulkErrorGrid.open({ title: "発注承認", records: errorRows });
                    this.$dialog.notify.error(`発注承認処理に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`発注を承認しました`, { timeout: 2300 });
                    this.$refs.approvalConfirmGrid.close();
                  }
                  break;
                case ApiStatus.consts.PROCESSING:
                  headers = { InvokeId: result.data.header.invokeId };
                  break;
                default:
                  exec = false;
                  this.redirectError();
                  break;
              }
            }
          } catch (error) {
            console.error("PurchaseSearch::onBtnApproval", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    // 納品予定日回答処理
    onBtnAnswerImport() {
      this.answerDialog.show = true;
    },
    onAnswerImportDialogClose() {
      this.answerDialog.show = false;
    },
    async onAnswerImportSubmit(records) {
      this.answerDialog.show = false;
      const removeRow = [];
      const afterRows = [];
      if (records) {
        this.statedPurchases.approved.forEach((approval) => {
          let diffFlg = false;
          records.forEach((order) => {
            if (order.purchaseId == approval.purchaseId) {
              approval.purchaseStatus = order.status;
              approval.deliveryTime = order.deliveryTime;
              approval.updateDate = order.updateDate;
              this.statedPurchases.answered.push(approval);
              removeRow.push(approval);
              diffFlg = true;
            }
          });
          if (!diffFlg) afterRows.push(approval);
        });
      }
      this.statedPurchases.approved = afterRows;
      if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
    },
    async onBtnAnswer() {
      this.$refs.answerConfirmGrid.open({
        records: JSON.parse(
          JSON.stringify(
            this.gridApi.getSelectedRows().filter((row) => {
              return !row.purchaseLocked;
            })
          )
        ),
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            const validError = [];
            records.forEach((row) => {
              const error = this.rules.required(row.deliveryTime);
              if (error === true) {
                updateModels.push({
                  purchaseId: row.purchaseId,
                  deliveryTime: row.deliveryTime,
                  updateDatetime: row.updateDate,
                });
              } else {
                validError.push({
                  purchaseId: row.purchaseId,
                  errorMessage: ["納品予定日は" + error],
                });
              }
            });
            if (validError.length > 0) {
              this.$refs.bulkErrorGrid.open({ title: "納品予定日回答", records: validError });
              this.$dialog.notify.error(`入力チェックエラーがあります。`, {
                timeout: 2300,
              });
              return;
            }
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("M020203", updateModels.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            // WAF対策で引数をすべてさきにAPIに送る
            // 3000件毎にスライス
            const sliceByNumber = (array, number) => {
              const length = Math.ceil(array.length / number);
              return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
            };
            let requestRecordsSplit = sliceByNumber(updateModels, 3000);
            let bulkId = null;
            // APIでマージして引数を保持
            for (let i = 0; i < requestRecordsSplit.length; i++) {
              var payloadParam = {
                orders: requestRecordsSplit[i],
                bulkId: bulkId,
              };
              console.log(payloadParam);
              const response = await this.$store.dispatch("purchase/bulkPayloadShipdate", payloadParam);
              let error = response.data?.header;
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                  bulkId = response.data.contents.bulkId;
                  break;
                default:
                  this.redirectError();
                  break;
              }
            }
            let exec = true;
            let headers = null;
            let content = {
              bulkId: bulkId,
            };
            while (exec) {
              await new Promise((resolve) => setTimeout(resolve, 1000));
              const result = await this.$store.dispatch("purchase/answer", {
                contents: content,
                header: headers,
              });

              let error = result.data?.header;

              const afterRows = [];
              const removeRow = [];
              const errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  exec = false;
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        purchaseId: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 更新分のレコード変更
                  if (result.data?.contents?.orders) {
                    this.statedPurchases.approved.forEach((approval) => {
                      let diffFlg = false;
                      result.data.contents.orders.forEach((order) => {
                        if (order.purchaseId == approval.purchaseId) {
                          approval.purchaseStatus = order.status;
                          approval.deliveryTime = order.deliveryTime;
                          approval.updateDate = order.updateDate;
                          this.statedPurchases.answered.push(approval);
                          removeRow.push(approval);
                          diffFlg = true;
                        }
                      });
                      if (!diffFlg) afterRows.push(approval);
                    });
                  }
                  this.statedPurchases.approved = afterRows;
                  if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                  if (errorRows.length > 0) {
                    if (removeRow.length > 0)
                      this.$refs.answerConfirmGrid.gridApi.applyTransaction({ remove: removeRow });
                    this.$refs.bulkErrorGrid.open({ title: "納品予定日回答", records: errorRows });
                    this.$dialog.notify.error(`納品予定日回答処理に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`納品予定日を回答しました`, { timeout: 2300 });
                    this.$refs.answerConfirmGrid.close();
                  }
                  break;
                case ApiStatus.consts.PROCESSING:
                  headers = { InvokeId: result.data.header.invokeId };
                  break;
                default:
                  this.redirectError();
                  break;
              }
            }
          } catch (error) {
            console.error("PurchaseSearch::onBtnAnswer", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    // 納品予約処理
    onBtnReserveImport() {
      this.reserveDialog.show = true;
    },
    onReserveImportDialogClose() {
      this.reserveDialog.show = false;
    },
    async onReserveImportSubmit(records) {
      this.reserveDialog.show = false;
      const removeRow = [];
      const afterRows = [];
      if (records) {
        this.statedPurchases.answered.forEach((approval) => {
          let diffFlg = false;
          records.forEach((order) => {
            if (order.purchaseId == approval.purchaseId) {
              approval.purchaseStatus = order.status;
              approval.deliveryReservation = order.deliveryReservation;
              approval.updateDate = order.updateDate;
              this.statedPurchases.shipped.push(approval);
              removeRow.push(approval);
              diffFlg = true;
            }
          });
          if (!diffFlg) afterRows.push(approval);
        });
      }
      this.statedPurchases.answered = afterRows;
      if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
    },
    async onBtnReserve() {
      this.$refs.reserveConfirmGrid.open({
        records: JSON.parse(
          JSON.stringify(
            this.gridApi.getSelectedRows().filter((row) => {
              return !row.purchaseLocked;
            })
          )
        ),
        onSubmit: async (records) => {
          try {
            this.loadingOn();
            let updateModels = [];
            const validError = [];
            records.forEach((row) => {
              const error = this.rules.required(row.deliveryReservation);
              if (error === true) {
                updateModels.push({
                  purchaseId: row.purchaseId,
                  deliveryReservation: row.deliveryReservation,
                  updateDatetime: row.updateDate,
                });
              } else {
                validError.push({
                  purchaseId: row.purchaseId,
                  errorMessage: ["納品予約は" + error],
                });
              }
            });
            if (validError.length > 0) {
              this.$refs.bulkErrorGrid.open({ title: "納品予約", records: validError });
              this.$dialog.notify.error(`入力チェックエラーがあります。`, {
                timeout: 2300,
              });
              return;
            }
            // 最大件数チェック
            const validDataSizeRet = await RequestUtils.validDataSize("M020204", updateModels.length, (limitSize) => {
              this.$dialog.notify.error(`最大処理件数（${limitSize}件）オーバーのため処理出来ませんでした。`, {
                timeout: 2300,
              });
              this.loadingOff();
            });
            if (!validDataSizeRet) {
              return;
            }
            // WAF対策で引数をすべてさきにAPIに送る
            // 3000件毎にスライス
            const sliceByNumber = (array, number) => {
              const length = Math.ceil(array.length / number);
              return new Array(length).fill().map((_, i) => array.slice(i * number, (i + 1) * number));
            };
            let requestRecordsSplit = sliceByNumber(updateModels, 3000);
            let bulkId = null;
            // APIでマージして引数を保持
            for (let i = 0; i < requestRecordsSplit.length; i++) {
              var payloadParam = {
                orders: requestRecordsSplit[i],
                bulkId: bulkId,
              };
              console.log(payloadParam);
              const response = await this.$store.dispatch("purchase/bulkPayloadDeliveryreservation", payloadParam);
              let error = response.data?.header;
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                  bulkId = response.data.contents.bulkId;
                  break;
                default:
                  this.redirectError();
                  break;
              }
            }
            let exec = true;
            let headers = null;
            let content = {
              bulkId: bulkId,
            };
            while (exec) {
              await new Promise((resolve) => setTimeout(resolve, 1000));
              const result = await this.$store.dispatch("purchase/reserve", {
                contents: content,
                header: headers,
              });

              let error = result.data?.header;

              const afterRows = [];
              const removeRow = [];
              const errorRows = [];
              switch (error.resultCode) {
                case ApiStatus.consts.SUCCESS:
                case ApiStatus.consts.BUSINESS_ERROR:
                case ApiStatus.consts.ALREADY_CHANGED:
                  exec = false;
                  // エラーメッセージ格納
                  if (error.messages) {
                    Object.keys(error.messages).forEach((key) => {
                      errorRows.push({
                        purchaseId: key,
                        errorMessage: error.messages[key],
                      });
                    });
                  }
                  // 更新分のレコード変更
                  if (result.data?.contents?.orders) {
                    this.statedPurchases.answered.forEach((approval) => {
                      let diffFlg = false;
                      result.data.contents.orders.forEach((order) => {
                        if (order.purchaseId == approval.purchaseId) {
                          approval.purchaseStatus = order.status;
                          approval.deliveryReservation = order.deliveryReservation;
                          approval.updateDate = order.updateDate;
                          this.statedPurchases.shipped.push(approval);
                          removeRow.push(approval);
                          diffFlg = true;
                        }
                      });
                      if (!diffFlg) afterRows.push(approval);
                    });
                  }
                  this.statedPurchases.answered = afterRows;

                  if (removeRow.length > 0) this.gridApi.applyTransaction({ remove: removeRow });
                  if (errorRows.length > 0) {
                    if (removeRow.length > 0)
                      this.$refs.reserveConfirmGrid.gridApi.applyTransaction({ remove: removeRow });
                    this.$refs.bulkErrorGrid.open({ title: "納品予約", records: errorRows });
                    this.$dialog.notify.error(`納品予約処理に失敗したデータが存在します。ご確認ください。`, {
                      timeout: 2300,
                    });
                  } else {
                    this.$dialog.notify.info(`納品予約が完了しました`, { timeout: 2300 });
                    this.$refs.reserveConfirmGrid.close();
                  }
                  break;
                case ApiStatus.consts.PROCESSING:
                  headers = { InvokeId: result.data.header.invokeId };
                  break;
                default:
                  exec = false;
                  this.redirectError();
                  break;
              }
            }
          } catch (error) {
            console.error("PurchaseSearch::onBtnReserve", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    purchaseOrderDateFromRules(value) {
      if (value == null || this.searchModel.purchaseOrderDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.purchaseOrderDateTo)) return "発注日Toより前の日付を指定してください";
      return true;
    },
    purchaseOrderDateToRules(value) {
      if (value == null || this.searchModel.purchaseOrderDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.purchaseOrderDateFrom))
        return "発注日Fromより後の日付を指定してください";
      return true;
    },
    deliveryReservationFromRules(value) {
      if (value == null || this.searchModel.deliveryReservationTo == null) return true;
      if (moment(value).isAfter(this.searchModel.deliveryReservationTo))
        return "納品予約Toより前の日付を指定してください";
      return true;
    },
    deliveryReservationToRules(value) {
      if (value == null || this.searchModel.deliveryReservationFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.deliveryReservationFrom))
        return "納品予約Fromより後の日付を指定してください";
      return true;
    },
    releaseDateFromRules(value) {
      if (value == null || this.searchModel.releaseDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.releaseDateTo)) return "発売日Toより前の日付を指定してください";
      return true;
    },
    releaseDateToRules(value) {
      if (value == null || this.searchModel.releaseDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.releaseDateFrom)) return "発売日Fromより後の日付を指定してください";
      return true;
    },
    responseDateFromRules(value) {
      if (value == null || this.searchModel.responseDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.responseDateTo)) return "回答日Toより前の日付を指定してください";
      return true;
    },
    responseDateToRules(value) {
      if (value == null || this.searchModel.responseDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.responseDateFrom))
        return "納品予約Fromより後の日付を指定してください";
      return true;
    },
    returnQuantityRule(value, purchaseQuantity) {
      if (value == null || !value.match(/^([1-9]\d*|0)$/)) return true;
      if (Number(value) > purchaseQuantity) return "返品数は発注数以下の数値を指定してください";
      return true;
    },
    onValidPurchaseReturn(row) {
      var ret = true;
      var messages = [];

      // 返品数
      this.setValidMessage(this.rules.required(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isNumber(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.rules.isMinNumber(1)(row.returnQuantity), "返品数", messages);
      this.setValidMessage(this.returnQuantityRule(row.returnQuantity, row.purchaseQuantity), "返品数", messages);
      // 商品状態
      this.setValidMessage(this.rules.required(row.productConditionType), "商品状態区分", messages);
      // 倉庫
      this.setValidMessage(this.rules.required(row.warehouseCd), "倉庫", messages);
      // 配送先
      this.setValidMessage(this.rules.required(row.supplierDeliveryCode?.value), "配送先", messages);
      if (messages.length > 0) ret = messages;

      return ret;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
  },
};
</script>
