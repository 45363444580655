<template>
  <v-container fluid style="height: 100%">
    <template v-for="(history, index) in histories">
      <v-row :key="history.dateTime" no-gutters>
        <v-col cols="9">{{ history.dateTime | moment }}</v-col>
        <v-col cols="3" class="text-right">{{ history.stockQuantity | comma }}</v-col>
      </v-row>
      <v-divider v-if="index != histories.length - 1" :key="index" class="mb-1"></v-divider>
    </template>
  </v-container>
</template>

<script>
import moment from "moment";
import { statuses as ApiStatus } from "../../libs/api-client";
import { comma } from "../../filter/NumberFilter";

export default {
  name: "StockHistory",
  props: ["janCode", "storageCode", "stockId"],
  data() {
    return {
      histories: {},
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("YYYY/MM/DD");
    },
    comma,
  },
  watch: {
    stockId() {
      this.init();
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      try {
        this.loadingOn();
        const param = {
          janCode: this.janCode,
          storageCode: this.storageCode,
        };
        const response = await this.$store.dispatch("stock/history", param);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        this.histories = response.data.contents.stockHistories;
      } catch (error) {
        console.error("OrderHistory::mounted", error);
      } finally {
        this.loadingOff();
      }
    },
  },
};
</script>
