<template>
  <v-card>
    <v-card-title>
      <span class="headline"><v-icon>mdi-database-edit</v-icon>回答入力</span>
      <v-menu :close-on-click="false" bottom right transition="scale-transition" origin="top left">
        <template v-slot:activator="{ on }">
          <v-chip class="ml-10" color="orange" outlined pill v-on="on">回答入力について</v-chip>
        </template>
        <v-card width="900">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="text-h5">回答入力について</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon>
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item class="ml-3">
              <v-row><v-col>回答したいレコードにチェックを入れて回答内容を入力します。</v-col></v-row>
            </v-list-item>
            <v-list-item class="ml-3">
              <v-row
                ><v-col
                  >複数のレコードにチェックを付けた状態で回答を入力すると、自動的に同一の内容が反映されます。</v-col
                ></v-row
              >
            </v-list-item>
            <v-list-item class="ml-3">
              <v-row
                ><v-col
                  >分納等の回答方法については<a
                    href="https://sites.google.com/cafereo.co.jp/delphoi-manual-supplier"
                    target="_blank"
                    >マニュアル</a
                  >をご参照ください。</v-col
                ></v-row
              >
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="purchaseBulkEditForm" lazy-validation>
        <v-container>
          <v-row dense class="text-h6">選択行：{{ selectedRows.length }}件</v-row>
          <v-row dense>
            <v-col cols="12" sm="12" :style="gridStyle">
              <ag-grid-vue
                class="ag-theme-alpine"
                style="height: 100%"
                :defaultColDef="defaultColDef"
                :columnDefs="columnDefs"
                :alwaysShowHorizontalScroll="true"
                :pagination="true"
                :suppressColumnVirtualisation="true"
                :enableCellTextSelection="true"
                paginationPageSize="20"
                :localeText="localeText"
                rowSelection="multiple"
                rowMultiSelectWithClick="false"
                suppressRowClickSelection="true"
                singleClickEdit="true"
                @grid-ready="onGridReady"
                @selection-changed="onSelectionChanged"
                @column-everything-changed="onColumnEverythingChanged"
                :columnTypes="columnTypes"
                :getRowNodeId="(data) => data.purchaseId"
                :frameworkComponents="frameworkComponents"
                @cell-editing-stopped="onCellEditingStopped"
                @cell-editing-started="onCellEditingStarted"
                @cell-clicked="handleCellClicked"
              >
              </ag-grid-vue>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onCancelClick">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onUpdateClick">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
    <error-grid-dialog
      ref="updateErrorGrid"
      width="80%"
      height="80%"
      icon=""
      title="回答入力"
      btnSubmit="登録"
      :columns="errorColmuns"
    ></error-grid-dialog>
  </v-card>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
  EditableCheckBoxColumn,
  EditableCheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import { LockedCellRenderer, ProtectCellRenderer } from "../../models/ag-grid/cellRenderers";
import { getColumnDef } from "../../models/ag-grid/helpers";
import SectionBlock from "../common/SectionBlock.vue";
import {
  DateCellEditor,
  CheckboxCellEditor,
  CustomDateCellEditor,
  AboutDateCellEditor,
  NumericCellEditor,
} from "../../components/ag-grid/cellEditors";
import { CheckboxCellRenderer } from "../../components/ag-grid/cellRenderers";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";
import moment from "moment";
import {} from "../../components/ag-grid/cellEditors";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import { MakerAnswerConfirm1_5 as MakerAnswerConfirm } from "../../consts/columns/PurchaseColumns";
import PurchaseMakerBulkEditColumns from "../../consts/editColumns/PurchaseMakerBulkEditColumns";
import DeliveryCompanys from "../../consts/DeliveryCompanys";
import CafereoPurchaseStatuses from "../../consts/purchaseStatus/CafereoPurchaseStatuses";
import ConvertUtils from "../../utils/ConvertUtils";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";

export default {
  name: "PurchaseMakerBulkEdit",
  props: ["inputModel"],
  components: {
    AgGridVue,
    ErrorGridDialog,
    /* eslint-disable vue/no-unused-components */
    CustomDateCellEditor,
    AboutDateCellEditor,
    NumericCellEditor,
    SectionBlock,
    CafereoUserField,
  },
  data() {
    return {
      updateModel: {},
      defaultModel: {
        shipDate: null,
        specifiedDeliveryDate: null,
        isReady: null,
        cartonNumber: null,
        useSagawa: null,
        useCharter: null,
        priorityAm: null,
        useOricon: null,
        gachaProducts: null,
        bundledDesignation: null,
        preShipment: null,
        cashOnDelivery: null,
        useShippingLabel: null,
        enhancedInspection: null,
        remarks: null,
      },
      constant: {
        purchaseBulkEditColumnsValues: PurchaseMakerBulkEditColumns.all(),
        purchaseBulkEditColumns: PurchaseMakerBulkEditColumns,
        deliveryCompanyList: DeliveryCompanys.all(),
      },
      responseType: true,
      defaultColDef: null,
      columnDefs: null,
      gridApi: null,
      columnApi: null,
      selectedRows: [],
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      updateList: [],
      settingSources: [],
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
        dpEditableColumn: EditableColumn,
        dpEditableDateColumn: EditableDateColumn,
        dpEditableCheckmarkColumn: EditableCheckmarkColumn,
        dpEditableCheckBoxColumn: EditableCheckBoxColumn,
      },
      frameworkComponents: {
        dpDateCellEditor: DateCellEditor,
        dpCheckboxCellEditor: CheckboxCellEditor,
        dpBooleanFilter: BooleanFilter,
        dpSelectionFilter: SelectionFilter,
        dpCellDatePicker: CustomDateCellEditor,
        dpCellAboutDatePicker: AboutDateCellEditor,
        dpNumericCellEditor: NumericCellEditor,
        dpCheckboxCellRenderer: CheckboxCellRenderer,
      },
      gridStyle: { height: "95%", "flex-basis": "auto" },
      errorColmuns: [
        { headerName: "JANCODE", field: "jancode" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      errorRows: [],
      updateSuccessRecords: [],
      stringToNumber: ConvertUtils.stringToNumber,
      rules: {
        required: Validation.required,
        isNumber: Validation.isNumber,
        maxLength: Validation.maxLength,
        rangeOfNumber: Validation.rangeOfNumber,
        validSelectColumn: (value) => this.validSelectColumn(value),
      },
      purchasePersonList: [],
      purchasePersonNames: "",
      lastClickedRowIndex: null,
    };
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    gridHeightSize(value) {
      console.log("gridheightsize:" + value);
      this.gridStyle.height = value - 130 + "px";
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    // ****************************
    // ag-grid定義
    // ****************************
    async setColumnDefs() {
      // カフェレオ担当者サジェスト
      let columnDefs = [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
          cellRenderer: (params) => {
            if (this.activeStatusTab === "unchangeApproved" && !params.data.productLocked) {
              return ProtectCellRenderer({ matcher: "purchaseLocked" })(params);
            } else {
              return LockedCellRenderer({ matcher: "purchaseLocked" })(params);
            }
          },
        },
      ];
      columnDefs = columnDefs.concat(MakerAnswerConfirm);
      // ag-grid内のvalidation等をセット
      getColumnDef(columnDefs, "purchaseQuantity").valueSetter = this.valueSetter4PurchaseQuantity;
      getColumnDef(columnDefs, "deliveryTime").valueSetter = this.valueSetter4DeliveryTime;
      return columnDefs;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;

      this.init(this.inputModel);

      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi.autoSizeColumns(allColumnIds);
      this.gridApi.refreshCells();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
    },
    valueSetter4PurchaseQuantity(params) {
      let isNumber = Validation.isNumber(params.newValue);
      if (isNumber !== true) {
        this.$dialog.notify.error("納品数は" + isNumber, { timeout: 2300 });
        params.data.errorPurchaseQuantity = true;
        return false;
      }
      if (params.data.purchaseStatus === CafereoPurchaseStatuses.ARRIVAL) {
        this.$dialog.notify.error("出荷済みのため納品数は変更できません。", { timeout: 2300 });
        params.data.errorPurchaseQuantity = true;
        return false;
      }
      params.data.errorPurchaseQuantity = false;
      params.data.purchaseQuantity = params.newValue;
      this.computePurchaseItems(params.data);
      return true;
    },
    valueSetter4DeliveryTime(params) {
      params.data.deliveryTime = params.newValue;
      if (params.newValue === "完売") {
        // 委託・直送で完売は数量が変わるので変更不可
        const selectedNodes = params.api.getSelectedNodes();
        if (
          this.isDirectDelivery(params.data) ||
          this.isPropositionDummy(params.data) ||
          selectedNodes.find((r) => this.isDirectDelivery(r.data)) ||
          selectedNodes.find((r) => this.isPropositionDummy(r.data))
        ) {
          this.$dialog.notify.error(
            `委託または直送で完売は設定できません(編集対象に委託または直送が含まれています。)`,
            {
              timeout: 2300,
            }
          );
          params.data.deliveryTime = params.oldValue;
        } else {
          params.data.purchaseQuantity = 0;
          params.data.updatePurchaseQuantity = true;
        }
      }
      return true;
    },
    // ****************************
    // 画面アクション定義
    // ****************************
    init(inputModel) {
      this.updateModel = this.defaultModel;
      this.gridApi.setRowData(JSON.parse(JSON.stringify(inputModel)));
      this.updateSuccessRecords = [];
      // 初期表示時、設定項目を１つ表示する。
      this.onAddSettingSource();
      if (this.$refs.purchaseBulkEditForm) {
        this.$refs.purchaseBulkEditForm.resetValidation();
      }
    },
    reset() {
      this.init(this.inputModel);
      this.updateSuccessRecords = [];
    },
    onCancelClick() {
      this.$emit("onDialogClose", this.updateSuccessRecords);
    },
    async onUpdateClick() {
      try {
        this.loadingOn();
        let updateModels = [];
        this.errorRows = [];
        var isValid = true;
        this.gridApi.forEachNode((record) => {
          var error = this.validateRow(record.data);
          if (error != true) {
            this.errorRows.push({
              jancode: record.data.janCode,
              errorMessage: error,
            });
            isValid = false;
          } else {
            updateModels.push(this.requestFormat(record.data));
          }
        });

        if (isValid) {
          const response = await this.$store.dispatch("purchase/answer1_5", updateModels);

          let error = response.data?.header;
          // 一覧更新レコード
          let updateRows = [];
          this.errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  this.errorRows.push({
                    jancode: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 一覧部の更新分のレコード設定
              if (response.data.contents) {
                this.gridApi.forEachNode((row) => {
                  response.data.contents.orders.forEach((purchase) => {
                    let isError = this.errorRows.some((r) => r.purchaseId === row.data.purchaseId);
                    if (purchase.purchaseId == row.data.purchaseId && !isError) {
                      this.updateSuccessRecords.push(purchase);
                      updateRows.push(purchase);
                    }
                  });
                });
              }
              // エラー表示
              if (this.errorRows.length > 0) {
                // 確認ダイアログから更新した分を削除(エラーがある場合のみ)
                this.gridApi.applyTransaction({ remove: updateRows });
                this.$refs.updateErrorGrid.open({ records: this.errorRows });
                this.$dialog.notify.error(`受注情報の更新処理に失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`受注情報を更新しました`, { timeout: 2300 });
                this.$refs.updateErrorGrid.close();
                console.log("受注情報を更新しました::");
                console.log(this.updateSuccessRecords);
                this.$emit("onEditSubmit", this.updateSuccessRecords);
                this.reset();
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } else {
          this.$refs.updateErrorGrid.open({ records: this.errorRows });
          this.$dialog.notify.error(`更新データに入力エラーが存在します。ご確認ください。`, {
            timeout: 2300,
          });
        }
      } catch (error) {
        console.error("PurchaseSearch::onBtnAnswer", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    requestFormat(row) {
      row.purchaseQuantity = Number(row.purchaseQuantity);
      row.updateDatetime = row.updateDate;
      return row;
    },
    handleCellClicked(event) {
      if (event.colDef.checkboxSelection) {
        const clickedRowIndex = event.rowIndex;

        if (event.event.shiftKey && this.lastClickedRowIndex !== null) {
          const start = Math.min(clickedRowIndex, this.lastClickedRowIndex);
          const end = Math.max(clickedRowIndex, this.lastClickedRowIndex);

          event.api.forEachNode((node, index) => {
            if (index >= start && index <= end) {
              node.setSelected(true);
            }
          });
        } else {
          event.api.forEachNode((node) => {
            if (node.rowIndex === clickedRowIndex) {
              node.setSelected(!node.isSelected());
            }
          });
        }

        this.lastClickedRowIndex = clickedRowIndex;
      }
    },
    onColumnEverythingChanged() {
      var allColumnIds = [];
      this.columnApi?.getAllColumns().forEach(function (column) {
        allColumnIds.push(column.colId);
      });
      this.columnApi?.autoSizeColumns(allColumnIds);
      this.gridApi?.refreshCells();
    },
    onCellEditingStarted(event) {
      const editingRow = event.node;
      const selectedNodes = event.api.getSelectedNodes();
      if (selectedNodes.length > 0 && !selectedNodes.includes(editingRow)) {
        // 編集中の行を除外して、他の行が選択されている場合は編集を強制的に終了する
        this.$dialog.notify.error(`複数行選択時は選択した行以外の項目を編集することはできません`, {
          timeout: 2300,
        });
        event.api.stopEditing();
        return;
      }
      if (
        (this.isPropositionDummy(editingRow.data) || selectedNodes.find((r) => this.isPropositionDummy(r.data))) &&
        event.colDef.field !== "deliveryTime"
      ) {
        this.$dialog.notify.error(`委託の発注で変更できるのは発注日のみです。(編集対象に委託が含まれています。)`, {
          timeout: 2300,
        });
        event.api.stopEditing();
        return;
      }
      if (
        (this.isDirectDelivery(editingRow.data) || selectedNodes.find((r) => this.isDirectDelivery(r.data))) &&
        event.colDef.field !== "deliveryTime"
      ) {
        this.$dialog.notify.error(`直送の発注で変更できるのは発注日のみです。(編集対象に直送が含まれています。)`, {
          timeout: 2300,
        });
        event.api.stopEditing();
        return;
      }
      const popupTop = event.api.getCellEditorInstances()[0].getGui().style.top;
      event.api.getCellEditorInstances()[0].getGui().style.top = parseInt(popupTop) - 40 + "px";
    },
    onCellEditingStopped(params) {
      console.log("onCellEditingStopped");
      const editingRow = params.node;
      const selectedNodes = params.api.getSelectedNodes();
      if (selectedNodes.length > 0 && !selectedNodes.includes(editingRow)) {
        return;
      }
      // 委託・直送の発注で変更できるのは発注日のみ
      // 発注日も完売は数量が変わるので不可
      if (
        (this.isPropositionDummy(params.data) ||
          selectedNodes.find((r) => this.isPropositionDummy(r.data)) ||
          this.isDirectDelivery(params.data) ||
          selectedNodes.find((r) => this.isDirectDelivery(r.data))) &&
        (params.colDef.field !== "deliveryTime" || params.newValue === "完売")
      ) {
        return;
      }
      if (params.colDef.cellRenderer === "dpCheckboxCellRenderer") {
        //NOP
      } else {
        if (ConvertUtils.utilize(params.newValue) === ConvertUtils.BLANK) {
          return;
        }
      }
      // セルの色を変えるため項目更新をチェック
      if (params.colDef.field == "purchaseQuantity") {
        params.data.updatePurchaseQuantity = true;
      } else if (params.colDef.field == "deliveryTime") {
        params.data.updateDeliveryTime = true;
      } else if (params.colDef.field == "installment") {
        params.data.updateInstallment = true;
      }
      let rows = [];
      rows.push(params.node);
      this.gridApi.refreshCells({ force: true, rowNodes: rows });
      if (this.selectedRows.length > 1) {
        this.settingSources = [];
        let settingSource = {
          column: 0,
          value: "",
        };
        if (params.colDef.field == "purchaseQuantity") {
          settingSource.column = 1;
        } else if (params.colDef.field == "deliveryTime") {
          settingSource.column = 2;
        } else if (params.colDef.field == "installment") {
          settingSource.column = 3;
        }
        settingSource.value = params.newValue;
        this.settingSources.push(settingSource);
        this.onBtnSetUpdate();
      }
    },
    onSettingColSelected(index) {
      this.settingSources[index].value = "";
    },
    onAddSettingSource() {
      let settingSource = {
        column: 0,
        value: "",
      };
      this.settingSources.push(settingSource);
    },
    onDelSettingSource(index) {
      this.settingSources.splice(index, 1);
    },
    onBtnSetUpdate() {
      this.loadingOn();
      if (this.validate()) {
        this.selectedRows.forEach((row) => {
          this.settingSources.forEach((source) => {
            if (source.column === this.constant.purchaseBulkEditColumns.PURCHASE_QUANTITY) {
              row.purchaseQuantity = source.value;
              row.updatePurchaseQuantity = true;
              this.computePurchaseItems(row);
            } else if (source.column === this.constant.purchaseBulkEditColumns.DELIVERY_TIME) {
              row.deliveryTime = source.value;
              row.updateDeliveryTime = true;
              if (source.value === "完売") {
                row.purchaseQuantity = 0;
                row.updatePurchaseQuantity = true;
                this.computePurchaseItems(row);
              }
            } else if (source.column === this.constant.purchaseBulkEditColumns.INSTALLMENT) {
              row.installment = source.value;
              row.updateInstallment = true;
            }
          });
        });
        this.gridApi.applyTransaction({ update: this.selectedRows });
        this.updateModel = this.defaultModel;
      }
      this.loadingOff();
    },
    // ****************************
    // 共通業務処理
    // ****************************
    computePurchaseItems(row) {
      row.purchasePcsQuantity = row.inBoxNumber * row.purchaseQuantity;
      if (row.retailPrice > row.unitPrice) {
        row.purchaseRate = Math.round((row.unitPrice / row.retailPrice) * 100);
      }
      row.purchasePriceTotal = row.unitPrice * row.purchaseQuantity;
    },

    // ****************************
    // Validation定義
    // ****************************
    validate() {
      const isValid = this.$refs.purchaseBulkEditForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
      }
      return isValid;
    },
    validateRow(row) {
      var ret = true;
      var messages = [];
      // 発注数
      this.setValidMessage(this.rules.required(row.purchaseQuantity), "納品数", messages);
      this.setValidMessage(this.rules.isNumber(row.purchaseQuantity), "納品数", messages);
      this.setValidMessage(this.rules.maxLength(8)(row.purchaseQuantity), "納品数", messages);
      this.setValidMessage(this.validPurchaseQuantity(row), "納品数", messages);

      // 納品予定日
      this.setValidMessage(this.rules.required(row.deliveryTime), "納品日", messages);
      this.setValidMessage(this.rules.maxLength(30)(row.deliveryTime), "納品日", messages);

      this.setValidMessage(this.validInstallment(row), "分納指定", messages);

      if (messages.length > 0) ret = messages;
      return ret;
    },
    isDate(value) {
      if (value == null || value == "") return true;
      if (!moment(value, "YYYY-MM-DD", true).isValid()) return "YYYY/MM/DD形式で入力してください";
      return true;
    },
    validUnitPrice(row) {
      if (row == null) return true;
      if (Number(row.retailPrice) < Number(row.unitPrice)) return "仕入価格が上代を超えています。";
      return true;
    },
    validPurchaseQuantity(row) {
      if (row == null) return true;
      if (Number(row.purchaseQuantity) > Number(row.originPurchaseQuantity)) return "発注数を超える数を指定できません";
      if (row.deliveryTime != "完売" && Number(row.purchaseQuantity) === 0) return "1以上を指定してください";
      if (
        row.deliveryTime == "完売" &&
        (this.isPropositionDummy(row) || this.isDirectDelivery(row.directDeliveryRequestFlg))
      )
        return "直送または委託の場合、完売は指定できません";
      if (row.deliveryTime == "完売" && Number(row.purchaseQuantity) !== 0) return "完売の時0以外指定できません";
      return true;
    },
    validInstallment(row) {
      if (row == null) return true;
      if (row.installment && row.deliveryTime == "完売") return "完売時に設定できません";
      return true;
    },
    validSelectColumn(value) {
      if (value == null) return true;
      if (this.settingSources.filter((settingSource) => settingSource.column === value).length > 1)
        return "同一項目が選択されています。";
      return true;
    },
    setValidMessage(check, culumnName, messages) {
      if (!(check === true)) messages.push(culumnName + "は" + check);
    },
    setCustomValidMessage(check, messages) {
      if (!(check === true)) messages.push(check);
    },
    isDirectDelivery(row) {
      return row.directDeliveryRequestFlg;
    },
    isPropositionDummy(row) {
      return row.purchaseType != 1 && row.purchaseRate === 0;
    },
  },

  async beforeMount() {
    this.domLayout = "autoHeight";
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = await this.setColumnDefs();
  },
  mounted() {
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize - 130 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy: function () {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
