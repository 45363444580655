<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title>
          <template><v-icon>mdi-book-search</v-icon>仮受注一覧</template>
        </v-app-bar-title>
        <v-spacer></v-spacer>
        <tooltip-icon-toggle-button icon="mdi-magnify" v-model="searchSelected">検索表示</tooltip-icon-toggle-button>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport">CSVダウンロード</tooltip-icon-button>
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col v-show="searchSelected">
        <v-card>
          <v-card-title>
            <span class="headline"><v-icon>mdi-magnify</v-icon>仮受注検索</span></v-card-title
          >
          <v-divider> </v-divider>
          <v-card-text>
            <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
              <v-row dense>
                <v-col cols="12" sm="12">
                  <label style="color: red">カフェレオから発注済みの商品は、検索対象外となります。</label>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="JANコード"
                    v-model="searchModel.janCodes[0]"
                    :rules="[rules.isJancode]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="JANコード"
                    v-model="searchModel.janCodes[1]"
                    :rules="[rules.isJancode]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="JANコード"
                    v-model="searchModel.janCodes[2]"
                    :rules="[rules.isJancode]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="JANコード"
                    v-model="searchModel.janCodes[3]"
                    :rules="[rules.isJancode]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="JANコード"
                    v-model="searchModel.janCodes[4]"
                    :rules="[rules.isJancode]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="JANコード"
                    v-model="searchModel.janCodes[5]"
                    :rules="[rules.isJancode]"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="onBtnSearch">検索</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="(!detailSelected || !infoMaximum) && !searchSelected"
      >
        <v-row>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                受注数合計：{{ this.orderQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                販売価格合計：￥{{ this.orderAmount.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <ag-grid-vue
          id="PurchaseList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :frameworkComponents="frameworkComponents"
          :localeText="localeText"
          rowSelection="multiple"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          :columnTypes="columnTypes"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          @filter-changed="onfilterChanged"
          :getRowNodeId="
            (data) => {
              return data.janCode;
            }
          "
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected && !searchSelected"
        id="PurchaseInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <temporary-purchase-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
        ></temporary-purchase-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import TemporaryPurchaseInfos from "../../components/temporaryPurchase/TemporaryPurchaseInfos.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
} from "../../models/ag-grid/columnTypes";
import { getDisplayDetails, typeFormat } from "../../models/ag-grid/helpers";
import MakerProductStatus from "../../consts/productStatus/MakerProductStatus";
import RecordTypes from "../../consts/ProductRecordTypes";
import { SelectionFilter } from "../../components/ag-grid/filters";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";

export default {
  name: "TemporaryPurchaseSearch",
  data() {
    return {
      gridStyle: { height: "95%" },
      validSeearchForm: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      autoSizeAll: null,
      infoMaximum: false,
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      searchSelected: true,
      orderQuantity: 0,
      orderAmount: 0,
      searchModel: {
        janCodes: [null, null, null, null, null, null],
      },
      frameworkComponents: {
        dpSelectionFilter: SelectionFilter,
      },
      rules: {
        isJancode: Validation.isJancode,
      },
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
    };
  },
  components: {
    AgGridVue,
    TemporaryPurchaseInfos,
    TooltipIconButton,
    TooltipIconToggleButton,
  },
  watch: {
    globalPageSize(value) {
      this.selectedPageSize = value;
      this.onPageSizeChanged();
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
  mounted() {
    this.searchSelected = true;
    this.handleResize();
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }
      return getDisplayDetails(this.selectedRow.janCode, this.gridApi, this.columnApi);
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = "仮受注一覧.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected = selectedRows.length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;

      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = null;
      }
    },
    // 検索ボタン押下処理
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.searchSelected = false;
        const response = await this.$store.dispatch("temporaryPurchase/search", {
          jancode: this.searchModel.janCodes.filter((jan) => jan),
        });
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = "仮受注一覧";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.orders).length === 0) {
          this.$dialog.warning({
            title: "仮受注一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(result.orders);
        console.log(result.orders);

        this.orderQuantity = 0;
        this.orderAmount = 0;
        result.orders.forEach((row) => {
          if (row.orderQuantity) this.orderQuantity += row.orderQuantity;
          if (row.unitPriceTotal) this.orderAmount += row.unitPriceTotal;
        });

        var allColumnIds = [];
        this.columnApi.getAllColumns().forEach(function (column) {
          allColumnIds.push(column.colId);
        });
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("TemporaryPurchaseSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onfilterChanged() {
      this.orderQuantity = 0;
      this.orderAmount = 0;
      this.gridApi.forEachNode((row) => {
        if (row.displayed) {
          if (row.data.orderQuantity) this.orderQuantity += row.data.orderQuantity;
          if (row.data.unitPriceTotal) this.orderAmount += row.data.unitPriceTotal;
        }
      });
    },
    onPageSizeChanged() {
      if (this.gridApi) this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
    onInfoMaximum() {
      this.gridApi.paginationSetPageSize(this.selectedPageSize);
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
      {
        headerName: "ステータス",
        field: "productStatus",
        filter: "dpSelectionFilter",
        filterParams: { options: MakerProductStatus.all() },
        valueGetter: (params) => MakerProductStatus.of(params.data.productStatus),
      },
      {
        headerName: "区分",
        field: "productType",
        filter: "dpSelectionFilter",
        filterParams: { options: RecordTypes.all() },
        valueGetter: (params) => RecordTypes.of(params.data.productType),
      },
      { headerName: "発売日", field: "releaseDate" },
      {
        headerName: "発注締日",
        field: "orderCloseDate",
        type: "dpDateColumn",
      },
      { headerName: "JANCODE", field: "janCode" },
      { headerName: "タイトル", field: "title" },
      { headerName: "商品名", field: "productName" },
      {
        headerName: "仮受注数",
        field: "orderQuantity",
        type: "dpNumericColumn",
      },
    ];

    this.rowData = [];
  },
};
</script>
