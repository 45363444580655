<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title> <v-icon>mdi-note-search</v-icon>不足商品管理 </v-app-bar-title>
        <v-spacer></v-spacer>
        <v-form ref="searchForm" v-model="validSeearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-row dense>
              <v-col>
                <v-select
                  :items="deficiencytatusList"
                  label="不足ステータス"
                  class="mx-2"
                  clearable
                  v-model="searchModel.deficiencyStatus"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="登録日From"
                  dense
                  type="date"
                  v-model="searchModel.createDateFrom"
                  :rules="[rules.createDateFrom]"
                ></dp-date-picker>
              </v-col>
              <v-col>
                <dp-date-picker
                  class="mx-2"
                  label="登録日To"
                  dense
                  type="date"
                  v-model="searchModel.createDateTo"
                  :rules="[rules.createDateTo]"
                ></dp-date-picker>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-textarea
                  class="mx-2"
                  v-if="isCafereoUser"
                  label="JANコード"
                  v-model="searchModel.janCode"
                  :rules="[rules.maxLength(1400), rules.isJancodeSearchList]"
                  dense
                  rows="1"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  class="mx-2"
                  v-if="isCafereoUser"
                  label="商品名"
                  v-model="searchModel.productName"
                  :rules="[rules.maxLength(150)]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-text-field
                  class="mx-2"
                  v-if="isCafereoUser"
                  label="仕入先略称"
                  v-model="searchModel.maker"
                  :rules="[rules.maxLength(150)]"
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          :disabled="!selectedRow"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          v-if="allowedAction(constants.updateActions)"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <deficiency-entry
            ref="deficiencyEntry"
            :inputModel="editModel"
            @onDialogClose="onDeficiencyDialogClose"
            @onEditSubmit="onDeficiencyEditSubmit"
            v-if="editDialog.show"
          ></deficiency-entry>
        </v-dialog>
        <tooltip-icon-button
          :disabled="disabledApproval"
          icon="mdi-check"
          @click="onBtnApproval(true)"
          v-if="allowedAction(constants.approvalActions)"
          >納品数承認</tooltip-icon-button
        >
        <tooltip-icon-button
          :disabled="disabledCancel"
          icon="mdi-pencil-remove-outline"
          @click="onBtnCancel"
          v-if="allowedAction(constants.cancelActions)"
          >キャンセル</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport" v-if="allowedAction(constants.csvActions)"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <v-row>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                発注数合計：{{ this.purchaseQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card elevation="1" class="mt-2">
              <v-card-text class="font-weight-bold ml-4 mb-4">
                不足数合計：{{ this.deficiencyQuantity.toLocaleString() }}
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <ag-grid-vue
          id="DeficiencyList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressColumnVirtualisation="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          @filter-changed="onfilterChanged"
          :getRowNodeId="
            (data) => {
              return data.deficiencyNumber;
            }
          "
        >
        </ag-grid-vue>
      </v-col>
      <v-col v-if="detailSelected" id="OrderInfos" style="flex-basis: auto; display: flex" :cols="infoMaximum ? 12 : 3">
        <v-divider vertical></v-divider>
        <deficiency-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :deficiencyNumber="selectedDeficiencyNumber"
        ></deficiency-infos>
      </v-col>
    </v-row>
    <confirm-grid-dialog
      ref="confirmGrid"
      width="80%"
      height="80%"
      icon="mdi-check"
      title="納品数承認"
      message="TESTTEST"
      :autoClose="false"
      :columns="approvalColumnDefs"
    ></confirm-grid-dialog>
    <error-grid-dialog ref="bulkErrorGrid" width="80%" height="80%" :columns="errorColmuns"></error-grid-dialog>
  </v-container>
</template>

<script>
import moment from "moment";
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import DeficiencyInfos from "../../components/deficiency/DeficiencyInfos.vue";
import DeficiencyEntry from "../../components/deficiency/DeficiencyEntry.vue";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";
import ConfirmGridDialog from "../../components/common/ConfirmGridDialog.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import DeficiencyStatuses from "../../consts/deficiencyStatus/CafereoDeficiencyStatuses";
import { BooleanFilter, SelectionFilter } from "../../components/ag-grid/filters";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  IncludeFilter,
} from "../../models/ag-grid/columnTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import Validation from "../../libs/validation";

export default {
  name: "CafereoDeficiencySearch",
  data() {
    return {
      gridStyle: { height: "95%" },
      // 権限グループ
      constants: {
        cafereoActions: ["C030401"],
        updateActions: ["C030402"],
        approvalActions: ["C030403"],
        cancelActions: ["C030404"],
        csvActions: ["C030405"],
      },
      rules: {
        maxLength: Validation.maxLength,
        isJancodeSearchList: Validation.isJancodeSearchList,
        createDateFrom: (value) => this.createDateFromRules(value),
        createDateTo: (value) => this.createDateToRules(value),
      },
      validSeearchForm: null,
      columnDefs: null,
      rowData: null,
      gridApi: null,
      columnApi: null,
      defaultColDef: null,
      defaultCsvExportParams: null,
      detailSelected: false,
      rowsSelected: null,
      infoMaximum: false,
      purchaseQuantity: 0,
      deficiencyQuantity: 0,
      deficiencytatusList: DeficiencyStatuses.all(),
      selectedPageSize: null,
      domLayout: null,
      localeText: AG_GRID_LOCALE_JA,
      selectedRow: null,
      searchModel: {
        deficiencyStatus: null,
        createDateFrom: null,
        createDateTo: null,
        janCode: "",
        productName: null,
        maker: null,
      },
      editDialog: { show: false, width: "1800px" },
      editModel: {
        createNew: false,
      },
      selectionRows: null,
      disabledCancel: true,
      disabledApproval: true,
      cafereoColumnDefs: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn", pinned: "left" },
        {
          headerName: "ステータス",
          field: "deficiencyStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: DeficiencyStatuses.all() },
          valueGetter: (params) => DeficiencyStatuses.of(params.data.deficiencyStatus),
          pinned: "left",
        },
        { headerName: "不足番号", field: "deficiencyNumber", pinned: "left" },
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "仕入先略称",
          field: "maker",
        },
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title", filterParams: IncludeFilter },
        { headerName: "商品名", field: "productName", filterParams: IncludeFilter },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "仕切単価",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "発注数",
          field: "purchaseQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "不足数",
          field: "deficiencyQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "補填品有無", field: "replacementExistence", type: "dpCheckmarkColumn" },
        { headerName: "納品予約", field: "deliveryReservation", type: "dpDateColumn" },
        { headerName: "検品結果", field: "inspectionResult", type: "dpCheckmarkColumn" },
        {
          headerName: "検品数",
          field: "inspectionQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
      ],
      approvalColumnDefs: [
        { headerName: "登録日", field: "createDatetime", type: "dpDateColumn" },
        {
          headerName: "ステータス",
          field: "deficiencyStatus",
          filter: "dpSelectionFilter",
          filterParams: { options: DeficiencyStatuses.all() },
          valueGetter: (params) => DeficiencyStatuses.of(params.data.deficiencyStatus),
        },
        { headerName: "不足番号", field: "deficiencyNumber" },
        { headerName: "発注ID", field: "purchaseId" },
        {
          headerName: "仕入先略称",
          field: "maker",
        },
        { headerName: "JANCODE", field: "jancode" },
        { headerName: "タイトル", field: "title" },
        { headerName: "商品名", field: "productName" },
        {
          headerName: "上代",
          field: "retailPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "仕入掛率",
          field: "purchaseRate",
          type: "dpPercentColumn",
        },
        {
          headerName: "仕切単価",
          field: "unitPrice",
          type: "dpNumericColumn",
        },
        {
          headerName: "発注数",
          field: "purchaseQuantity",
          type: "dpNumericColumn",
        },
        {
          headerName: "不足数",
          field: "deficiencyQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "補填品有無", field: "replacementExistence", type: "dpCheckmarkColumn" },
        { headerName: "納品予約", field: "deliveryReservation", type: "dpDateColumn" },
        { headerName: "検品結果", field: "inspectionResult", type: "dpCheckmarkColumn" },
        {
          headerName: "検品数",
          field: "inspectionQuantity",
          type: "dpNumericColumn",
        },
        { headerName: "登録日時", field: "createDatetime", type: "dpFullDateColumn" },
        { headerName: "登録者", field: "createUser" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpFullDateColumn" },
        { headerName: "更新者", field: "updateUser" },
      ],
      errorColmuns: [
        { headerName: "不足番号", field: "deficiencyNumber" },
        {
          headerName: "エラー内容",
          field: "errorMessage",
          wrapText: true,
          autoHeight: true,
          cellRenderer: function (param) {
            return param.data.errorMessage.join("<br>");
          },
        },
      ],
      columnTypes: {
        dpNumericColumn: NumericColumn,
        dpPercentColumn: PercentColumn,
        dpDateColumn: DateColumn,
        dpFullDateColumn: FullDateColumn,
        dpCheckmarkColumn: CheckmarkColumn,
      },
      frameworkComponents: {
        dpSelectionFilter: SelectionFilter,
        dpBooleanFilter: BooleanFilter,
      },
    };
  },
  components: {
    AgGridVue,
    DeficiencyInfos,
    DeficiencyEntry,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ConfirmGridDialog,
    ErrorGridDialog,
  },
  mounted() {
    this.$store.commit("ui/loading", true);
    this.onSearchClick();
    this.gridStyle.height = this.gridHeightSize - 96.5 + "px";
    window.addEventListener("resize", this.handleResize);
  },
  computed: {
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }

      let detailColumns = this.columnApi
        .getAllColumns()
        .filter((column) => {
          return !column.colDef.hide;
        })
        .map((column) => {
          let coldef = { ...column.colDef };
          if (
            coldef.field == "createDatetime" ||
            coldef.field == "updateDatetime" ||
            coldef.field == "deliveryReservation"
          ) {
            coldef.type = "dpFullDateColumn";
          }
          return coldef;
        });
      return getDisplayDetails2(this.selectedRow.deficiencyNumber, detailColumns, this.gridApi);
    },
    selectedDeficiencyNumber() {
      if (!this.selectedRow) {
        return null;
      }
      return this.selectedRow.deficiencyNumber;
    },
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value - 96.5 + "px";
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.defaultCsvExportParams.fileName = "不足一覧.csv";
      this.gridApi.exportDataAsCsv({
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      var selectedRows = [];
      selectedRows = this.gridApi.getSelectedRows();
      this.rowsSelected = selectedRows.length > 0;
      this.defaultCsvExportParams.onlySelectedAllPages = this.rowsSelected;
      this.selectionRows = selectedRows;
      if (selectedRows.length === 1) {
        this.selectedRow = selectedRows[0];
      } else {
        this.selectedRow = null;
      }
      let cancelCount = 0;
      let approvalCount = 0;
      this.selectionRows.forEach((row) => {
        if (row.deficiencyStatus != DeficiencyStatuses.notshipped && row.deficiencyStatus != DeficiencyStatuses.cancel)
          cancelCount = cancelCount + 1;
        if (row.deficiencyStatus == DeficiencyStatuses.unapproved) approvalCount = approvalCount + 1;
      });

      this.disabledApproval = true;
      this.disabledCancel = true;
      if (this.selectionRows.length > 0 && cancelCount == this.selectionRows.length) this.disabledCancel = false;
      if (this.selectionRows.length > 0 && approvalCount == this.selectionRows.length) this.disabledApproval = false;
    },
    onBtnUpdate() {
      this.editModel = {
        createNew: false,
        ...this.selectedRow,
      };
      this.editDialog.show = true;
    },
    async onBtnCancel() {
      let messageText = `選択された不足情報をキャンセルします(${this.selectionRows.length}件)`;
      const ok = await this.$dialog.confirm({ title: "不足一覧", text: messageText });
      if (ok) {
        try {
          this.loadingOn();
          let updateModels = [];
          this.selectionRows.forEach((row) => {
            updateModels.push({
              deficiencyNumber: row.deficiencyNumber,
              lastUpdateDatetime: row.updateDatetime,
            });
          });
          var requestContents = {
            cancelConditions: updateModels,
          };
          const result = await this.$store.dispatch("deficiency/cancel", requestContents);

          let error = result.data?.header;

          const updateRow = [];
          const errorRows = [];
          switch (error.resultCode) {
            case ApiStatus.consts.SUCCESS:
            case ApiStatus.consts.BUSINESS_ERROR:
            case ApiStatus.consts.ALREADY_CHANGED:
              // エラーメッセージ格納
              if (error.messages) {
                Object.keys(error.messages).forEach((key) => {
                  errorRows.push({
                    deficiencyNumber: key,
                    errorMessage: error.messages[key],
                  });
                });
              }
              // 更新分のレコード変更
              this.selectionRows.forEach((statusData) => {
                result.data.contents.deficiencies.forEach((deficiency) => {
                  if (deficiency.deficiencyNumber == statusData.deficiencyNumber) {
                    statusData.deficiencyStatus = deficiency.status;
                    statusData.updateDatetime = deficiency.updateDatetime;
                    updateRow.push(statusData);
                  }
                });
              });
              if (updateRow.length > 0) this.gridApi.applyTransaction({ update: updateRow });
              if (errorRows.length > 0) {
                this.$refs.bulkErrorGrid.open({ title: "不足キャンセル", records: errorRows });
                this.$dialog.notify.error(`不足キャンセルに失敗したデータが存在します。ご確認ください。`, {
                  timeout: 2300,
                });
              } else {
                this.$dialog.notify.info(`不足をキャンセルしました`, { timeout: 2300 });
              }
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CafereoDeficiencySearch::onBtnCancel", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        var params = { ...this.searchModel };
        // JANコードを１行の文字列から配列(半角スペース区切り)へ変更
        params.janCode = this.searchModel.janCode
          ? this.searchModel.janCode
              .replaceAll(/\n+/g, " ")
              .split(" ")
              .filter((n) => n)
          : null;
        const response = await this.$store.dispatch("deficiency/search", params);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }
        var result = response.data.contents;
        if (result.over) {
          let title = "不足商品管理";
          this.$dialog.warning({
            title: title,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.deficiencies).length === 0) {
          this.$dialog.warning({
            title: "不足商品管理",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        if (this.gridApi) {
          this.gridApi.setRowData(result.deficiencies);
          const allColumnIds = this.columnApi.getAllColumns().map((column) => {
            return column.colId;
          });
          this.columnApi.autoSizeColumns(allColumnIds);

          this.purchaseQuantity = 0;
          this.deficiencyQuantity = 0;
          for (let row of result.deficiencies) {
            if (row.purchaseQuantity) {
              this.purchaseQuantity += row.purchaseQuantity;
            }
            if (row.deficiencyQuantity) {
              this.deficiencyQuantity += row.deficiencyQuantity;
            }
          }
        }
      } catch (error) {
        console.error("CafereoDeficiencySearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onfilterChanged() {
      this.purchaseQuantity = 0;
      this.deficiencyQuantity = 0;
      this.gridApi.forEachNode((row) => {
        if (row.displayed) {
          if (row.data.purchaseQuantity) {
            this.purchaseQuantity += row.data.purchaseQuantity;
          }
          if (row.data.deficiencyQuantity) {
            this.deficiencyQuantity += row.data.deficiencyQuantity;
          }
        }
      });
    },
    onDeficiencyDialogClose() {
      this.editDialog.show = false;
    },
    onDeficiencyEditSubmit(updateModel) {
      this.editDialog.show = false;
      this.gridApi.applyTransaction({ update: [updateModel] });
      this.onSelectionChanged();
    },
    onBtnApproval() {
      this.$refs.confirmGrid.open({
        records: this.selectionRows,
        columnTypes: this.columnTypes,
        onSubmit: async () => {
          try {
            this.loadingOn();
            let updateModels = [];
            this.selectionRows.forEach((row) => {
              updateModels.push({
                deficiencyNumber: row.deficiencyNumber,
                updateDatetime: row.updateDatetime,
              });
            });
            var requestContents = {
              deficiencies: updateModels,
            };
            const result = await this.$store.dispatch("deficiency/approval", requestContents);

            let error = result.data?.header;

            const updateRow = [];
            const errorRows = [];
            switch (error.resultCode) {
              case ApiStatus.consts.SUCCESS:
              case ApiStatus.consts.BUSINESS_ERROR:
              case ApiStatus.consts.ALREADY_CHANGED:
                // エラーメッセージ格納
                if (error.messages) {
                  Object.keys(error.messages).forEach((key) => {
                    errorRows.push({
                      deficiencyNumber: key,
                      errorMessage: error.messages[key],
                    });
                  });
                }
                // 更新分のレコード変更
                this.selectionRows.forEach((statusData) => {
                  result.data.contents.deficiencies.forEach((deficiency) => {
                    if (deficiency.deficiencyNumber == statusData.deficiencyNumber) {
                      statusData.deficiencyStatus = deficiency.status;
                      statusData.updateDatetime = deficiency.updateDatetime;
                      updateRow.push(statusData);
                    }
                  });
                });
                if (updateRow.length > 0) this.gridApi.applyTransaction({ update: updateRow });
                if (errorRows.length > 0) {
                  this.$refs.bulkErrorGrid.open({ title: "納品数承認", records: errorRows });
                  this.$dialog.notify.error(`納品数承認に失敗したデータが存在します。ご確認ください。`, {
                    timeout: 2300,
                  });
                } else {
                  this.$dialog.notify.info(`納品数を承認しました`, { timeout: 2300 });
                  this.$refs.confirmGrid.close();
                }
                break;
              default:
                this.redirectError();
                break;
            }
          } catch (error) {
            console.error("DeficiencySearch::approvalConfirmGrid onsubmit", error);
            this.apiRequestError(error);
          } finally {
            this.loadingOff();
          }
        },
      });
    },
    createDateFromRules(value) {
      if (value == null || this.searchModel.createDateTo == null) return true;
      if (moment(value).isAfter(this.searchModel.createDateTo)) return "登録日Toより前の日付を指定してください";
      return true;
    },
    createDateToRules(value) {
      if (value == null || this.searchModel.createDateFrom == null) return true;
      if (moment(value).isBefore(this.searchModel.createDateFrom)) return "登録日Fromより後の日付を指定してください";
      return true;
    },
  },
  beforeMount() {
    this.domLayout = "autoHeight";
    this.selectedPageSize = this.globalPageSize;
    this.defaultCsvExportParams = {
      allColumns: true,
      onlySelectedAllPages: false,
    };
    this.defaultColDef = {
      filter: "agTextColumnFilter",
      resizable: true,
      sortable: true,
      suppressSizeToFit: true,
      filterParams: {
        newRowsAction: "keep",
      },
    };
    this.columnDefs = [
      {
        headerName: "",
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        filter: false,
        resizable: false,
        sortable: false,
        pinned: "left",
      },
    ];
    this.columnDefs = this.columnDefs.concat(this.cafereoColumnDefs);

    this.rowData = [];
  },
};
</script>
